import * as React from "react";
import { useQuery } from "react-apollo";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { InternalServerError } from "../home/HttpStatus";
import SimpleLoadingIndicator from "../common/SimpleLoadingIndicator";
import { getPropertyByIdQuery } from "./graphql";
import PhotoCarousel from "./PhotoCarousel";
import { GetPropertyByIdQuery, GetPropertyByIdQueryVariables, PropertyActiveStatus } from "./types";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import { PropertyTypeLanguages, propertyTypeOptions } from "../common/propertyTypeOptions";
import { useSelector } from "react-redux";
import { RootState } from "../reducer";
import MMText from "../common/MMText";
import {
  DisplayLanguage,
  formatArea,
  formatCurrency,
  formatFloor,
  LanguageDefination,
  LanguageText,
} from "../common/DisplayLanguage";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar/Avatar";
import LocationOn from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid/Grid";
import { LandTypeLanguage, landTypeOptions } from "../common/landTypeOptions";
import { formatNumber } from "accounting";
import CheckCircle from "@material-ui/icons/CheckCircle";
import Box from "@material-ui/core/Box/Box";
import useTheme from "@material-ui/core/styles/useTheme";
import PropertyStatusBadge from "./PropertyStatusBadge";
import makeStyles from "@material-ui/core/styles/makeStyles";
import BankLoanAvailavilityBadge from "./BankLoanAvailavilityBadge";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { Helmet } from "react-helmet";
import { PropertyStatusLanguage } from "../common/propertyStatusOptions";
import settings from "../settings";
import { isSSR } from "../utility";
type FeatureProps = {
  name: React.ReactNode;
  children: React.ReactNode;
};
const useStyles = makeStyles((theme) => ({
  vAlignMiddle: {
    display: "flex",
    alignItems: "center",
  },
  carouselGrid: {
    width: "100%",
  },
  featureGrid: {
    padding: theme.spacing(1),
  },
  featureValue: {
    height: 24,
  },
  root: {
    padding: 0,
    paddingTop: theme.spacing(1),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));
function Feature({ name, children }: FeatureProps) {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Grid item xs={6} md={4}>
      <Box
        className="bg-down-1"
        margin={theme.spacing(0.125)}
        padding={theme.spacing(0.125)}
      >
        <Typography align="center" variant="body1">
          {name}
        </Typography>
        <Typography
          align="center"
          className={classes.featureValue}
          variant="body2"
          color="textSecondary"
        >
          {children}
        </Typography>
      </Box>
    </Grid>
  );
}
type CheckBoxFeaturePorps = { name: React.ReactNode; checked: boolean };
function CheckBoxFeature({ name, checked }: CheckBoxFeaturePorps) {
  if (!checked) return null;
  return <Feature name={name}>{<CheckCircle color="secondary" />}</Feature>;
}

export type PropertyProps = {} & RouteComponentProps<{ id: string }>;
function Property({
  match: {
    params: { id },
  },
}: PropertyProps) {
  const { loading, data: propertyByIdResult, error } = useQuery<
    GetPropertyByIdQuery,
    GetPropertyByIdQueryVariables
  >(getPropertyByIdQuery, {
    variables: {
      id,
    },
    errorPolicy: "all",
    fetchPolicy: "cache-and-network",
  });

  const classes = useStyles();
  const theme = useTheme();
  const language = useSelector(
    (state: RootState) => state.site.userSession.language
  );
  const property = propertyByIdResult?.propertyQuery?.property;
  const propertyTypes = React.useMemo(() => propertyTypeOptions(), [language]);
  const landTypes = React.useMemo(() => landTypeOptions(), [language]);
  const areaText = React.useMemo(() => {
    return formatArea(
      language,
      property?.width,
      property?.length,
      property?.area
    );
  }, [language, property?.width, property?.length, property?.area]);
  const availability = React.useMemo(()=>{
    switch(property?.activeStatus){
      case PropertyActiveStatus.ACTIVE:
        return "in stock";
      case PropertyActiveStatus.SOLD_OUT:
        return "out of stock";
      case PropertyActiveStatus.ARCHIVED:
      default:
        return "discontinued";
    }
  }, [property?.activeStatus]);
  const floorText = React.useMemo(() => {
    return formatFloor(property?.floor, property?.propertyType, language);
  }, [property?.floor, language, property?.propertyType]);

  const height = React.useMemo(() => {
    if (!property?.height) return null;
    return (
      <Feature name={<LanguageText text={LanguageDefination.height} />}>
        <LanguageText
          prefix={`${formatNumber(property?.height, 0)} `}
          text={LanguageDefination.ft}
        />
      </Feature>
    );
  }, [property?.height, language]);

  const priceText = React.useMemo(() => {
    return formatCurrency(property?.price, language);
  }, [language, property?.price]);
  if (error) {
    return <InternalServerError error={error.message} />;
  }
  const ogTitle = React.useMemo(()=>{
    if(!property)
      return "Hein Property";
    const propertyType = PropertyTypeLanguages[property?.propertyType][DisplayLanguage.mm];
    const floor = formatFloor(property?.floor, property?.propertyType, DisplayLanguage.mm);
    const area = formatArea(DisplayLanguage.mm, property?.width,property?.length, property?.area);
    const city = property?.city? `${property?.city?.alias}/${property?.city?.region?.alias}` : "";
    const array = [propertyType];
    if(floor)
      array.push(floor);
    if(area)
      array.push(area);
    if(city)
      array.push(city);
    return array.join(" ");
  }, [property]);
  const history = useHistory();
  React.useEffect(()=>{
      if(property && !isSSR){
        var w = window as any;
        w.fbq('track', 'ViewContent', {
          content_ids:[property?.id],
          content_type:"product"
        });
      }
    
  }, [property]);
  return (
    <>
      <Helmet>
        <title>
          {`${property?.propertyCode} ${propertyTypes[property?.propertyType]?.text}`}
        </title>
        <meta property="og:type" content="product.item"></meta>
        <meta property="og:title" content={ogTitle}></meta>
        <meta property="og:description" content={property?.about}></meta>
        <meta property="og:url" content={`https://heinproperty.com/property/${property?.id}`}></meta>
        <meta property="product:brand" content="Hein Property"></meta>
        <meta property="product:availability" content={availability} ></meta>
        <meta property="product:catalog_id" content={property?.id}></meta>
        <meta property="product:condition" content="new"/>
        <meta property="product:custom_label_0" content={formatCurrency(property?.price, DisplayLanguage.mm)}/>
        <meta property="product:custom_label_1" content={property?.propertyStatus? PropertyStatusLanguage[property?.propertyStatus][DisplayLanguage.mm] : ""}></meta>
        <meta property="product:custom_label_2" content={property?.landType? LandTypeLanguage[property?.landType][DisplayLanguage.mm]:""}></meta>
        <meta property="product:custom_label_4" content={`${property?.city?.alias} (${property?.city.name})`}></meta>
        <meta property="product:price:amount" content={property?.price?.toString()}></meta>
        <meta property="product:price:currency" content="MMK"></meta>
        <meta property="product:retailer_item_id" content={property?.id}></meta>
        {
          property?.propertyPhotos?.map(photo=> (<meta key={photo.id} property="og:image" content={`${settings.cloudfront_url}${photo.url}`}></meta>))
        }
      </Helmet>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="Back"
            onClick={() => {
              history.goBack();
            }}
          >
            <ArrowBack />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {`${property?.propertyCode ?? ""} ${
              propertyTypes[property?.propertyType]?.text ?? ""
            }`}
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {property ? (
        <Container className={classes.root} maxWidth="lg">
          <Grid container>
            <Grid item md={6} className={classes.carouselGrid}>
              <PhotoCarousel photos={property?.propertyPhotos} />
            </Grid>
            <Grid item md={6}>
              <div>
                <Box padding={theme.spacing(0.25)}>
                  <div className={classes.vAlignMiddle}>
                    <Typography variant="h3" component="span">
                      {property?.propertyCode}
                    </Typography>
                    <PropertyStatusBadge
                      propertyStatus={property?.propertyStatus}
                    />
                  </div>
                  <Typography variant="h4">
                    {propertyTypes[property?.propertyType]?.text}
                  </Typography>
                  <div className={classes.vAlignMiddle}>
                    <Typography variant="h6" component="span">
                      {priceText}
                    </Typography>
                    <BankLoanAvailavilityBadge
                      value={property?.homeLoanAvailable}
                    />
                  </div>
                  <Typography variant="body1">
                    <MMText>{property?.about}</MMText>
                  </Typography>
                </Box>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <LocationOn color="secondary" />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <LanguageText
                          useSkeleton={false}
                          text={{
                            en: property?.city?.name,
                            mm: property?.city?.alias,
                          }}
                        />
                        /
                        <LanguageText
                          useSkeleton={false}
                          text={{
                            en: property?.city?.region?.name,
                            mm: property?.city?.region.alias,
                          }}
                        />
                      </>
                    }
                    secondary={property?.address}
                  />
                </ListItem>
                <Grid className={classes.featureGrid} container>
                  <Feature
                    name={<LanguageText text={LanguageDefination.landType} />}
                  >
                    {landTypes[property?.landType]?.text}
                  </Feature>
                  <Feature
                    name={<LanguageText text={LanguageDefination.dimension} />}
                  >
                    {areaText}
                  </Feature>
                  {height}
                  <Feature
                    name={
                      <LanguageText text={LanguageDefination.floorOrStoery} />
                    }
                  >
                    {floorText}
                  </Feature>
                  <CheckBoxFeature
                    name={<LanguageText text={LanguageDefination.elevator} />}
                    checked={property?.elevator}
                  />
                  <CheckBoxFeature
                    name={
                      <LanguageText text={LanguageDefination.swimmingPool} />
                    }
                    checked={property?.swimmingPool}
                  />
                  <CheckBoxFeature
                    name={
                      <LanguageText
                        text={LanguageDefination.electricAndWater}
                      />
                    }
                    checked={property?.electricAndWaterMeter}
                  />
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Container>
      ) : null}
      {loading ? <SimpleLoadingIndicator /> : null}
    </>
  );
}

export default Property;
