import * as React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab/Tab";
import Tabs from "@material-ui/core/Tabs/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { PropertyStatus } from "../property/types";
import { propertyStatusOptionList } from "../common/propertyStatusOptions";
import { RootState } from "../reducer";
import PropertyTypeChips from "../property/PropertyTypeChips";
import PropertyMasonry from "../property/PropertyMasonry";
import { siteActions } from "../reducer/site";
import { DisplayLanguage, LanguageDefination } from "../common/DisplayLanguage";
import MainToolBar from "./MainToolBar";
import makeStyles from "@material-ui/core/styles/makeStyles";
import qs from "query-string";
import { isSSR } from "../utility";
import ScreenInfoContext from "../common/ScreenInfoContext";
import { NotFound } from "./HttpStatus";
import Property from "../property";
import useGlobalStyle from "../globalStyle";
import { Helmet } from "react-helmet";
const useStyles = makeStyles((theme) => ({
  sticky: {
    position: "fixed",
    top: 0,
  },
  offset: {
    height: 96,
  },
}));
function Home({
  history,
  match: {
    params: { status },
  },
}: RouteComponentProps<{ status: PropertyStatus }>) {
  const appBar = React.useRef<HTMLElement>(null);
  const offset = React.useRef<HTMLDivElement>(null);
  const onWindowScroll = React.useCallback(() => {
    if (window.pageYOffset > 64 - (window.screen.width < 600 ? 8 : 0)) {
      appBar.current.classList.add(classes.sticky);
      offset.current.classList.add(classes.offset);
    } else {
      appBar.current.classList.remove(classes.sticky);
      offset.current.classList.remove(classes.offset);
    }
  }, []);
  React.useEffect(() => {
    if (!isSSR) {
      window.addEventListener("scroll", onWindowScroll);
      return () => window.removeEventListener("scroll", onWindowScroll);
    }
  }, []);
  const selectedLanguage = useSelector(
    (state: RootState) => state.site.userSession.language
  );
  const dispatch = useDispatch();
  const propertyStatusOptions = React.useMemo(() => {
    if (!selectedLanguage) {
      dispatch(siteActions.setLanguage(DisplayLanguage.en));
    }
    return propertyStatusOptionList();
  }, [selectedLanguage]);
  const selectedStatus = status ?? "";
  const tabs = React.useMemo(() => {
    return propertyStatusOptions.map(({ value, text }) => (
      <Tab key={value} label={text} value={value} />
    ));
  }, [propertyStatusOptions, selectedStatus]);
  const classes = useStyles();
  const screenInfo = React.useContext(ScreenInfoContext);
  return (
    <>
      <MainToolBar />
      <AppBar ref={appBar} position="static">
        <Tabs
          centered={screenInfo.type == "phone"}
          value={selectedStatus}
          onChange={(_, newValue) => {
            window.scrollTo(0, 0);
            const params = qs.parse(location.search, {
              arrayFormat: "comma",
            });
            history.push(
              qs.stringifyUrl(
                {
                  url: `/${newValue}`,
                  query: { city: params.city },
                },
                { arrayFormat: "comma" }
              )
            );
          }}
          aria-label="Property status"
        >
          <Tab label={LanguageDefination.all[selectedLanguage]} value={""} />
          {tabs}
        </Tabs>
        <PropertyTypeChips />
      </AppBar>
      <div ref={offset} />
      <PropertyMasonry />
    </>
  );
}
export default () => {
  useGlobalStyle();
  return (
    <>
      <Helmet
        titleTemplate="%s | Hein Property"
        defaultTitle="Hein Property"
      ></Helmet>
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/:status" exact component={Home} />
        <Route path="/property/:id" component={Property} />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};
