import * as React from "react";
import { PropertyPhotoGraphType } from "./resolvedTypes";
import { Carousel } from "react-responsive-carousel";
import settings from "../settings";
import { isSSR } from "../utility";
import ReactDOM from "react-dom";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Close from "@material-ui/icons/Close";
import ScreenInfoContext from "../common/ScreenInfoContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
export type PhotoCarouselProps = {
  photos: PropertyPhotoGraphType[];
};

const useStyles = makeStyles((theme) => ({
  navbtn: {
    position: "absolute",
    top: "50%",
    cursor: "pointer",
    zIndex: 2,
    backgroundColor: "rgba(0,0,0,.3)",
  },
  prevbtn: {
    left: theme.spacing(1),
  },
  nextbtn: {
    right: theme.spacing(1),
  },
  closebtn: {
    top: theme.spacing(3),
    left: theme.spacing(3),
    position: "absolute",
    backgroundColor: "rgba(0,0,0,.3)",
    zIndex: 1200,
  },
}));

const portalRoot = !isSSR ? document.getElementById("portal") : null;
function PhotoCarousel({ photos }: PhotoCarouselProps) {
  const classes = useStyles();
  const container = React.useRef<Element>(null);
  React.useEffect(() => {
    container.current = document.createElement("div");
    if (portalRoot) {
      portalRoot.appendChild(container.current);
      return () => {
        portalRoot.removeChild(container.current);
        document.body.style.overflow = "";
      };
    }
  }, []);
  const [portalMode, setPortalMode] = React.useState(false);
  const images = React.useMemo(
    () =>
      photos
        ? photos.map(({ id, url }) => (
            <div key={id}>
              <img src={`${settings.cloudfront_url}${url}`} />
            </div>
          ))
        : null,
    [photos]
  );

  React.useEffect(() => {
    if (portalMode) {
      window.scrollTo(0, 0);
      container.current.classList.add("backdrop");
      document.body.style.overflow = "hidden";
    } else {
      container.current.classList.remove("backdrop");
      document.body.style.overflow = "";
    }
  }, [portalMode]);

  const renderNextBtn = React.useCallback(
    (onClick, hasNext) => (
      <IconButton
        size="small"
        className={`${classes.navbtn} ${classes.nextbtn}`}
        color="secondary"
        onClick={onClick}
      >
        <ChevronRight />
      </IconButton>
    ),
    []
  );
  const renderPrevBtn = React.useCallback(
    (onClick, hasPrev) => (
      <IconButton
        size="small"
        className={`${classes.navbtn} ${classes.prevbtn}`}
        color="secondary"
        onClick={onClick}
      >
        <ChevronLeft />
      </IconButton>
    ),
    []
  );

  const screenInfo = React.useContext(ScreenInfoContext);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const carousel = (
    <>
      {portalMode ? (
        <IconButton
          color="secondary"
          onClick={() => {
            setPortalMode(false);
          }}
          className={classes.closebtn}
        >
          <Close />
        </IconButton>
      ) : null}
      <Carousel
        selectedItem={selectedIndex}
        onChange={setSelectedIndex}
        onClickItem={() => {
          if (!portalMode) setPortalMode(true);
        }}
        showArrows={false}
        renderArrowNext={screenInfo.type == "phone" ? undefined : renderNextBtn}
        renderArrowPrev={screenInfo.type == "phone" ? undefined : renderPrevBtn}
        className={portalMode ? "fullscreen" : ""}
        swipeable
        showThumbs={!portalMode && screenInfo.width >= 600}
        useKeyboardArrows={portalMode}
        dynamicHeight
      >
        {images}
      </Carousel>
    </>
  );
  if (portalMode && container.current)
    return ReactDOM.createPortal(carousel, container.current);
  else return carousel;
}

export default PhotoCarousel;
