import makeStyles from "@material-ui/core/styles/makeStyles";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import * as React from "react";
import settings from "../settings";
import LanguageSelector from "./LanguageSelector";
import RegionSelector from "./RegionSelector";
const useStyles = makeStyles((theme) => ({
  logo: {
    backgroundImage: `url(${settings.cloudfront_url}logo.png)`,
    backgroundSize: "contain",
    height: 40,
    width: 132,
  },
  toolbar: {
    backgroundColor: "#fff",
  },
  grow:{
      flex:1
  }
}));
function MainToolBar() {
  const classes = useStyles();
  return (
    <Toolbar className={classes.toolbar}>
      <div className={classes.logo} />
      <div className={classes.grow} />
      <RegionSelector/>
      <LanguageSelector/>
    </Toolbar>
  );
}

export default MainToolBar;
