/* tslint:disable */
//  This file was automatically generated and should not be edited.

export interface RegionWhereInput {
  aND?: Array< RegionWhereInput > | null,
  oR?: Array< RegionWhereInput > | null,
  id?: string | null,
  id_Not?: string | null,
  id_In?: Array< string > | null,
  id_NotIn?: Array< string > | null,
  id_Lt?: string | null,
  id_Lte?: string | null,
  id_Gt?: string | null,
  id_Gte?: string | null,
  id_Contains?: string | null,
  id_NotContains?: string | null,
  id_StartsWith?: string | null,
  id_NotStartsWith?: string | null,
  id_EndsWith?: string | null,
  id_NotEndsWith?: string | null,
  name?: string | null,
  name_Not?: string | null,
  name_In?: Array< string > | null,
  name_NotIn?: Array< string > | null,
  name_Lt?: string | null,
  name_Lte?: string | null,
  name_Gt?: string | null,
  name_Gte?: string | null,
  name_Contains?: string | null,
  name_NotContains?: string | null,
  name_StartsWith?: string | null,
  name_NotStartsWith?: string | null,
  name_EndsWith?: string | null,
  name_NotEndsWith?: string | null,
  alias?: string | null,
  alias_Not?: string | null,
  alias_In?: Array< string > | null,
  alias_NotIn?: Array< string > | null,
  alias_Lt?: string | null,
  alias_Lte?: string | null,
  alias_Gt?: string | null,
  alias_Gte?: string | null,
  alias_Contains?: string | null,
  alias_NotContains?: string | null,
  alias_StartsWith?: string | null,
  alias_NotStartsWith?: string | null,
  alias_EndsWith?: string | null,
  alias_NotEndsWith?: string | null,
};

export interface PaginationInput {
  page?: number | null,
  pageSize?: number | null,
  skip?: number | null,
  take?: number | null,
};

export enum RegionOrderBy {
  name = "name", // Order by name.
  name_Desc = "name_Desc", // Order by name desc.
  sortOrder = "sortOrder", // Order by sort order.
  sortOrder_Desc = "sortOrder_Desc", // Order by sort order desc.
}


export interface CityWhereInput {
  aND?: Array< CityWhereInput > | null,
  oR?: Array< CityWhereInput > | null,
  id?: string | null,
  id_Not?: string | null,
  id_In?: Array< string > | null,
  id_NotIn?: Array< string > | null,
  id_Lt?: string | null,
  id_Lte?: string | null,
  id_Gt?: string | null,
  id_Gte?: string | null,
  id_Contains?: string | null,
  id_NotContains?: string | null,
  id_StartsWith?: string | null,
  id_NotStartsWith?: string | null,
  id_EndsWith?: string | null,
  id_NotEndsWith?: string | null,
  name?: string | null,
  name_Not?: string | null,
  name_In?: Array< string > | null,
  name_NotIn?: Array< string > | null,
  name_Lt?: string | null,
  name_Lte?: string | null,
  name_Gt?: string | null,
  name_Gte?: string | null,
  name_Contains?: string | null,
  name_NotContains?: string | null,
  name_StartsWith?: string | null,
  name_NotStartsWith?: string | null,
  name_EndsWith?: string | null,
  name_NotEndsWith?: string | null,
  alias?: string | null,
  alias_Not?: string | null,
  alias_In?: Array< string > | null,
  alias_NotIn?: Array< string > | null,
  alias_Lt?: string | null,
  alias_Lte?: string | null,
  alias_Gt?: string | null,
  alias_Gte?: string | null,
  alias_Contains?: string | null,
  alias_NotContains?: string | null,
  alias_StartsWith?: string | null,
  alias_NotStartsWith?: string | null,
  alias_EndsWith?: string | null,
  alias_NotEndsWith?: string | null,
  region?: RegionWhereInput | null,
};

export enum CityOrderBy {
  name = "name", // Order by name.
  name_Desc = "name_Desc", // Order by name desc.
  region_Name = "region_Name", // Order by region name.
  region_Name_Desc = "region_Name_Desc", // Order by region name desc.
  sortOrder = "sortOrder", // Order by sort order.
  sortOrder_Desc = "sortOrder_Desc", // Order by sort order desc.
}


export interface RegionFindAllQueryVariables {
  where?: RegionWhereInput | null,
  pagination?: PaginationInput | null,
  orderBy?: Array< RegionOrderBy | null > | null,
};

export interface RegionFindAllQuery {
  regionQuery:  {
    regions:  {
      // Pagination info.
      pageInfo:  {
        // Current page number of result set.
        currentPage: number | null,
        // If true, next page is availabe.
        hasNextPage: boolean | null,
        // If true, previous page is available
        hasPreviousPage: boolean | null,
        // Total number of pages to be fetched
        pageCount: number | null,
        // Number of rows in a page
        pageSize: number | null,
        // Total number of rows to be fetched
        rowCount: number | null,
      },
      // Actual list of connection
      edges:  Array< {
        id: string | null,
        alias: string | null,
        name: string,
        sortOrder: number | null,
      } >,
    } | null,
  } | null,
};

export interface CityFindByIdQueryVariables {
  id: string,
};

export interface CityFindByIdQuery {
  cityQuery:  {
    city:  {
      id: string | null,
      alias: string | null,
      name: string,
    } | null,
  } | null,
};

export interface CityFindAllQueryVariables {
  where?: CityWhereInput | null,
  pagination?: PaginationInput | null,
  orderBy?: Array< CityOrderBy | null > | null,
};

export interface CityFindAllQuery {
  cityQuery:  {
    cities:  {
      // Pagination info.
      pageInfo:  {
        // Current page number of result set.
        currentPage: number | null,
        // If true, next page is availabe.
        hasNextPage: boolean | null,
        // If true, previous page is available
        hasPreviousPage: boolean | null,
        // Total number of pages to be fetched
        pageCount: number | null,
        // Number of rows in a page
        pageSize: number | null,
        // Total number of rows to be fetched
        rowCount: number | null,
      },
      // Actual list of connection
      edges:  Array< {
        id: string | null,
        alias: string | null,
        name: string,
        region:  {
          id: string | null,
          alias: string | null,
          name: string,
        } | null,
      } >,
    } | null,
  } | null,
};
