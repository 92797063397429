import Avatar from "@material-ui/core/Avatar/Avatar";
import Button from "@material-ui/core/Button/Button";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import * as React from "react";
import {  useQuery } from "react-apollo";
import { useLocation } from "react-router-dom";
import MMText from "../common/MMText";
import { cityFindAllQuery } from "./graphql";
import { CityFindAllQuery, CityFindAllQueryVariables } from "./types";
import qs from "query-string";
import update from "immutability-helper";
import { CityGraphType } from "./resolvedTypes";
import List from "@material-ui/core/List/List";
import Chip from "@material-ui/core/Chip/Chip";
import { LanguageText } from "../common/DisplayLanguage";
import makeStyles from "@material-ui/core/styles/makeStyles";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import SearchIcon from "@material-ui/icons/Search";
import { fade } from "@material-ui/core/styles/colorManipulator";
import MMTextInputBase from "../common/MMTextInputBase";
import SimpleLoadingIndicator from "../common/SimpleLoadingIndicator";
import { isSSR } from "../utility";
export type CitySelectorProps = {
  open: boolean;
  onClose: () => void;
  regionId: string;
  onSelected: (selectedCities: CityGraphType[]) => void;
};
const useStyles = makeStyles((theme) => ({
  offset: {
    width: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipScroller: {
    overflowX: "scroll",
    whiteSpace: "nowrap",
    marginBottom: -20,
    paddingBottom: 20,
  },
  chips: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    width: "100%",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  list: {
    height: (isSSR ? 600 : window.screen.height) - 164,
  },
}));
export function CitySelector({
  open,
  onClose,
  regionId,
  onSelected,
}: CitySelectorProps) {
  const classes = useStyles();
  const [search, setSearch] = React.useState("");
  const { data, loading: fetchingCities } = useQuery<
    CityFindAllQuery,
    CityFindAllQueryVariables
  >(cityFindAllQuery, {
    variables: {
      where: {
        region: {
          id: regionId,
        },
        oR: [{ name_Contains: search }, { alias_Contains: search }],
      },
      pagination: {
        pageSize: 1000,
      },
    },
    skip: !regionId,
  });
  const location = useLocation();
  const params = React.useMemo(() => {
    return qs.parse(location.search, { arrayFormat: "comma" });
  }, [location.search]);

  const paramCity = React.useMemo(() => {
    if (typeof params.city == "string") {
      return [params.city];
    } else if (params.city) {
      return params.city as string[];
    } else {
      return [];
    }
  }, [params]);

  const { data: selectedCities, loading: gettingSelectedCities } = useQuery<
    CityFindAllQuery,
    CityFindAllQueryVariables
  >(cityFindAllQuery, {
    variables: {
      where: {
        id_In: paramCity,
      },
      pagination: {
        pageSize: 1000,
      },
    },
  });

  const [draft, setDraft] = React.useState<CityGraphType[]>([]);

  React.useEffect(() => {
    if (paramCity.length == 0) setDraft([]);
    else {
      const selected = selectedCities?.cityQuery?.cities?.edges;
      setDraft(selected ? selected : []);
    }
  }, [selectedCities, paramCity]);
  const handleToggle = React.useCallback(
    (city: CityGraphType, checked: boolean) => {
      if (checked) {
        setDraft(update(draft, { $push: [city] }));
      } else {
        setDraft(
          update(draft, {
            $splice: [[draft.findIndex((c) => c.id == city.id), 1]],
          })
        );
      }
    },
    [draft, setDraft]
  );
  const cityListItems = React.useMemo(() => {
    const cities = data?.cityQuery?.cities?.edges;
    if (!cities) return null;
    return cities.map((city) => (
      <ListItem
        button
        key={city.id}
        onClick={() => {
          handleToggle(city, !(draft.findIndex((c) => c.id == city.id) !== -1));
        }}
      >
        <ListItemAvatar>
          <Avatar>{city.name[0].toUpperCase()}</Avatar>
        </ListItemAvatar>
        <ListItemText
          id={city.id}
          primary={city.name}
          secondary={<MMText>{city.alias}</MMText>}
        />
        <ListItemSecondaryAction>
          <Checkbox
            name={city.id}
            edge="end"
            onChange={(_, checked) => {
              handleToggle(city, checked);
            }}
            checked={draft.findIndex((c) => c.id == city.id) !== -1}
            inputProps={{ "aria-labelledby": city.id }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    ));
  }, [data, draft]);
  const chips = React.useMemo(() => {
    return draft.map((d) => (
      <Chip
        size="small"
        className={classes.chip}
        key={d.id}
        label={<LanguageText text={{ en: d.name, mm: d.alias }} />}
        onDelete={() => {
          handleToggle(d, false);
        }}
      />
    ));
  }, [draft]);
  const handleOnOkClick = React.useCallback(() => {
    onSelected(draft);
  }, [selectedCities?.cityQuery?.cities?.edges, draft]);
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={onClose}
      aria-labelledby="city-dialog-title"
    >
      <AppBar position="static">
        <Toolbar variant="dense">
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <MMTextInputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              value={search}
              inputProps={{ "aria-label": "search" }}
              onChange={(e, unicodeText) => {
                setSearch(unicodeText);
              }}
            />
          </div>
        </Toolbar>

        <div className={classes.chips}>
          <div className={classes.chipScroller}>
            <div className={classes.offset} />
            {chips}
          </div>
        </div>
      </AppBar>
      <DialogContent className={classes.list}>
        <List>{cityListItems}</List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button color="primary" onClick={handleOnOkClick}>
          OK
        </Button>
      </DialogActions>
      {gettingSelectedCities || fetchingCities ? (
        <SimpleLoadingIndicator />
      ) : null}
    </Dialog>
  );
}
