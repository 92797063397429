import InputBase, {
  InputBaseProps,
} from "@material-ui/core/InputBase/InputBase";
import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducer";
import { DisplayLanguage } from "./DisplayLanguage";
import { Rabbit } from "./Rabbit";
export type MMTextInputBaseProps = Omit<InputBaseProps, "onChange"> & {
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    unicodeText: string
  ) => void;
};
function MMTextInputBase({ value, onChange, ...props }: MMTextInputBaseProps) {
  const language = useSelector(
    (state: RootState) => state.site.userSession.language
  );
  const draft = React.useMemo(() => {
    if (value == null) return value;
    switch (language) {
      case DisplayLanguage.zg:
        return Rabbit.uni2zg(value.toString());
        break;
      default:
        return value;
        break;
    }
  }, [language, value]);

  return (
    <InputBase
      value={draft}
      onChange={(e) => {
        if (!onChange) return;
        if (!e.target.value) onChange(e, e.target.value);
        switch (language) {
          case DisplayLanguage.zg:
            const unicodeText = Rabbit.zg2uni(e.target.value);
            onChange(e, unicodeText);
            break;
          default:
            onChange(e, e.target.value);
            break;
        }
      }}
      {...props}
    />
  );
}
export default MMTextInputBase;
