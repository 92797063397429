/* tslint:disable */
//  This file was automatically generated and should not be edited.

export interface PropertyWhereInput {
  aND?: Array< PropertyWhereInput > | null,
  oR?: Array< PropertyWhereInput > | null,
  id?: string | null,
  id_Not?: string | null,
  id_In?: Array< string > | null,
  id_NotIn?: Array< string > | null,
  id_Lt?: string | null,
  id_Lte?: string | null,
  id_Gt?: string | null,
  id_Gte?: string | null,
  id_Contains?: string | null,
  id_NotContains?: string | null,
  id_StartsWith?: string | null,
  id_NotStartsWith?: string | null,
  id_EndsWith?: string | null,
  id_NotEndsWith?: string | null,
  propertyCode?: string | null,
  propertyCode_Not?: string | null,
  propertyCode_In?: Array< string > | null,
  propertyCode_NotIn?: Array< string > | null,
  propertyCode_Lt?: string | null,
  propertyCode_Lte?: string | null,
  propertyCode_Gt?: string | null,
  propertyCode_Gte?: string | null,
  propertyCode_Contains?: string | null,
  propertyCode_NotContains?: string | null,
  propertyCode_StartsWith?: string | null,
  propertyCode_NotStartsWith?: string | null,
  propertyCode_EndsWith?: string | null,
  propertyCode_NotEndsWith?: string | null,
  price?: number | null,
  price_Not?: number | null,
  price_Lt?: number | null,
  price_Lte?: number | null,
  price_Gt?: number | null,
  price_Gte?: number | null,
  price_In?: Array< string > | null,
  price_NotIn?: Array< string > | null,
  price_IsBlank?: boolean | null,
  area?: number | null,
  area_Not?: number | null,
  area_Lt?: number | null,
  area_Lte?: number | null,
  area_Gt?: number | null,
  area_Gte?: number | null,
  area_In?: Array< string > | null,
  area_NotIn?: Array< string > | null,
  area_IsBlank?: boolean | null,
  length?: number | null,
  length_Not?: number | null,
  length_Lt?: number | null,
  length_Lte?: number | null,
  length_Gt?: number | null,
  length_Gte?: number | null,
  length_In?: Array< string > | null,
  length_NotIn?: Array< string > | null,
  length_IsBlank?: boolean | null,
  width?: number | null,
  width_Not?: number | null,
  width_Lt?: number | null,
  width_Lte?: number | null,
  width_Gt?: number | null,
  width_Gte?: number | null,
  width_In?: Array< string > | null,
  width_NotIn?: Array< string > | null,
  width_IsBlank?: boolean | null,
  height?: number | null,
  height_Not?: number | null,
  height_Lt?: number | null,
  height_Lte?: number | null,
  height_Gt?: number | null,
  height_Gte?: number | null,
  height_In?: Array< string > | null,
  height_NotIn?: Array< string > | null,
  height_IsBlank?: boolean | null,
  mBRoom?: number | null,
  mBRoom_Not?: number | null,
  mBRoom_Lt?: number | null,
  mBRoom_Lte?: number | null,
  mBRoom_Gt?: number | null,
  mBRoom_Gte?: number | null,
  mBRoom_In?: Array< number > | null,
  mBRoom_NotIn?: Array< number > | null,
  mBRoom_IsBlank?: boolean | null,
  sBRoom?: number | null,
  sBRoom_Not?: number | null,
  sBRoom_Lt?: number | null,
  sBRoom_Lte?: number | null,
  sBRoom_Gt?: number | null,
  sBRoom_Gte?: number | null,
  sBRoom_In?: Array< number > | null,
  sBRoom_NotIn?: Array< number > | null,
  sBRoom_IsBlank?: boolean | null,
  floor?: number | null,
  floor_Not?: number | null,
  floor_Lt?: number | null,
  floor_Lte?: number | null,
  floor_Gt?: number | null,
  floor_Gte?: number | null,
  floor_In?: Array< number > | null,
  floor_NotIn?: Array< number > | null,
  floor_IsBlank?: boolean | null,
  address?: string | null,
  address_Not?: string | null,
  address_In?: Array< string > | null,
  address_NotIn?: Array< string > | null,
  address_Lt?: string | null,
  address_Lte?: string | null,
  address_Gt?: string | null,
  address_Gte?: string | null,
  address_Contains?: string | null,
  address_NotContains?: string | null,
  address_StartsWith?: string | null,
  address_NotStartsWith?: string | null,
  address_EndsWith?: string | null,
  address_NotEndsWith?: string | null,
  elevator?: boolean | null,
  swimmingPool?: boolean | null,
  electricAndWaterMeter?: boolean | null,
  homeLoanAvailable?: boolean | null,
  about?: string | null,
  about_Not?: string | null,
  about_In?: Array< string > | null,
  about_NotIn?: Array< string > | null,
  about_Lt?: string | null,
  about_Lte?: string | null,
  about_Gt?: string | null,
  about_Gte?: string | null,
  about_Contains?: string | null,
  about_NotContains?: string | null,
  about_StartsWith?: string | null,
  about_NotStartsWith?: string | null,
  about_EndsWith?: string | null,
  about_NotEndsWith?: string | null,
  propertyType?: PropertyType | null,
  propertyType_Not?: PropertyType | null,
  propertyType_In?: Array< PropertyType | null > | null,
  propertyType_NotIn?: Array< PropertyType | null > | null,
  propertyStatus?: PropertyStatus | null,
  propertyStatus_Not?: PropertyStatus | null,
  propertyStatus_In?: Array< PropertyStatus | null > | null,
  propertyStatus_NotIn?: Array< PropertyStatus | null > | null,
  landType?: LandType | null,
  landType_Not?: LandType | null,
  landType_In?: Array< LandType | null > | null,
  landType_NotIn?: Array< LandType | null > | null,
  pricingMethod?: PricingMethod | null,
  pricingMethod_Not?: PricingMethod | null,
  pricingMethod_In?: Array< PricingMethod | null > | null,
  pricingMethod_NotIn?: Array< PricingMethod | null > | null,
  activeStatus?: PropertyActiveStatus | null,
  activeStatus_Not?: PropertyActiveStatus | null,
  activeStatus_In?: Array< PropertyActiveStatus | null > | null,
  activeStatus_NotIn?: Array< PropertyActiveStatus | null > | null,
  owner?: OwnerWhereInput | null,
  city?: CityWhereInput | null,
};

export enum PropertyType {
  APARTMENT = "APARTMENT", // Apartment
  MINI_CONDO = "MINI_CONDO", // Mini Condo
  CONDO = "CONDO", // Condo
  LAND = "LAND", // Land
  HOUSING = "HOUSING", // Housing
  ZONE = "ZONE", // Zone
  COMMERCIAL = "COMMERCIAL", // Commercial
  OFFICE = "OFFICE", // Office
}


export enum PropertyStatus {
  RENT = "RENT", // Rend
  SELL = "SELL", // Sell
  PROJECT = "PROJECT", // Project
}


export enum LandType {
  FREEHOLD = "FREEHOLD", // Freehold Land
  GRANT = "GRANT", // Grant Land
  AGRICULTURAL = "AGRICULTURAL", // Agricultural Land
  GARDEN = "GARDEN", // Garden Land
  ZONE = "ZONE", // Zone Land
}


export enum PricingMethod {
  NFA = "NFA", // NFA
  GFA = "GFA", // GFA
}


export enum PropertyActiveStatus {
  ACTIVE = "ACTIVE", // Active
  SOLD_OUT = "SOLD_OUT", // Sold out
  ARCHIVED = "ARCHIVED", // Archived
}


export interface OwnerWhereInput {
  aND?: Array< OwnerWhereInput > | null,
  oR?: Array< OwnerWhereInput > | null,
  id?: string | null,
  id_Not?: string | null,
  id_In?: Array< string > | null,
  id_NotIn?: Array< string > | null,
  id_Lt?: string | null,
  id_Lte?: string | null,
  id_Gt?: string | null,
  id_Gte?: string | null,
  id_Contains?: string | null,
  id_NotContains?: string | null,
  id_StartsWith?: string | null,
  id_NotStartsWith?: string | null,
  id_EndsWith?: string | null,
  id_NotEndsWith?: string | null,
  code?: string | null,
  code_Not?: string | null,
  code_In?: Array< string > | null,
  code_NotIn?: Array< string > | null,
  code_Lt?: string | null,
  code_Lte?: string | null,
  code_Gt?: string | null,
  code_Gte?: string | null,
  code_Contains?: string | null,
  code_NotContains?: string | null,
  code_StartsWith?: string | null,
  code_NotStartsWith?: string | null,
  code_EndsWith?: string | null,
  code_NotEndsWith?: string | null,
  name?: string | null,
  name_Not?: string | null,
  name_In?: Array< string > | null,
  name_NotIn?: Array< string > | null,
  name_Lt?: string | null,
  name_Lte?: string | null,
  name_Gt?: string | null,
  name_Gte?: string | null,
  name_Contains?: string | null,
  name_NotContains?: string | null,
  name_StartsWith?: string | null,
  name_NotStartsWith?: string | null,
  name_EndsWith?: string | null,
  name_NotEndsWith?: string | null,
  contactPerson?: string | null,
  contactPerson_Not?: string | null,
  contactPerson_In?: Array< string > | null,
  contactPerson_NotIn?: Array< string > | null,
  contactPerson_Lt?: string | null,
  contactPerson_Lte?: string | null,
  contactPerson_Gt?: string | null,
  contactPerson_Gte?: string | null,
  contactPerson_Contains?: string | null,
  contactPerson_NotContains?: string | null,
  contactPerson_StartsWith?: string | null,
  contactPerson_NotStartsWith?: string | null,
  contactPerson_EndsWith?: string | null,
  contactPerson_NotEndsWith?: string | null,
  contactNumber?: string | null,
  contactNumber_Not?: string | null,
  contactNumber_In?: Array< string > | null,
  contactNumber_NotIn?: Array< string > | null,
  contactNumber_Lt?: string | null,
  contactNumber_Lte?: string | null,
  contactNumber_Gt?: string | null,
  contactNumber_Gte?: string | null,
  contactNumber_Contains?: string | null,
  contactNumber_NotContains?: string | null,
  contactNumber_StartsWith?: string | null,
  contactNumber_NotStartsWith?: string | null,
  contactNumber_EndsWith?: string | null,
  contactNumber_NotEndsWith?: string | null,
  address?: string | null,
  address_Not?: string | null,
  address_In?: Array< string > | null,
  address_NotIn?: Array< string > | null,
  address_Lt?: string | null,
  address_Lte?: string | null,
  address_Gt?: string | null,
  address_Gte?: string | null,
  address_Contains?: string | null,
  address_NotContains?: string | null,
  address_StartsWith?: string | null,
  address_NotStartsWith?: string | null,
  address_EndsWith?: string | null,
  address_NotEndsWith?: string | null,
  ownerType?: OwnerType | null,
  ownerType_Not?: OwnerType | null,
  ownerType_In?: Array< OwnerType | null > | null,
  ownerType_NotIn?: Array< OwnerType | null > | null,
};

export enum OwnerType {
  COMPANY = "COMPANY", // Company
  INDIVIDUAL = "INDIVIDUAL", // Individual
}


export interface CityWhereInput {
  aND?: Array< CityWhereInput > | null,
  oR?: Array< CityWhereInput > | null,
  id?: string | null,
  id_Not?: string | null,
  id_In?: Array< string > | null,
  id_NotIn?: Array< string > | null,
  id_Lt?: string | null,
  id_Lte?: string | null,
  id_Gt?: string | null,
  id_Gte?: string | null,
  id_Contains?: string | null,
  id_NotContains?: string | null,
  id_StartsWith?: string | null,
  id_NotStartsWith?: string | null,
  id_EndsWith?: string | null,
  id_NotEndsWith?: string | null,
  name?: string | null,
  name_Not?: string | null,
  name_In?: Array< string > | null,
  name_NotIn?: Array< string > | null,
  name_Lt?: string | null,
  name_Lte?: string | null,
  name_Gt?: string | null,
  name_Gte?: string | null,
  name_Contains?: string | null,
  name_NotContains?: string | null,
  name_StartsWith?: string | null,
  name_NotStartsWith?: string | null,
  name_EndsWith?: string | null,
  name_NotEndsWith?: string | null,
  alias?: string | null,
  alias_Not?: string | null,
  alias_In?: Array< string > | null,
  alias_NotIn?: Array< string > | null,
  alias_Lt?: string | null,
  alias_Lte?: string | null,
  alias_Gt?: string | null,
  alias_Gte?: string | null,
  alias_Contains?: string | null,
  alias_NotContains?: string | null,
  alias_StartsWith?: string | null,
  alias_NotStartsWith?: string | null,
  alias_EndsWith?: string | null,
  alias_NotEndsWith?: string | null,
  region?: RegionWhereInput | null,
};

export interface RegionWhereInput {
  aND?: Array< RegionWhereInput > | null,
  oR?: Array< RegionWhereInput > | null,
  id?: string | null,
  id_Not?: string | null,
  id_In?: Array< string > | null,
  id_NotIn?: Array< string > | null,
  id_Lt?: string | null,
  id_Lte?: string | null,
  id_Gt?: string | null,
  id_Gte?: string | null,
  id_Contains?: string | null,
  id_NotContains?: string | null,
  id_StartsWith?: string | null,
  id_NotStartsWith?: string | null,
  id_EndsWith?: string | null,
  id_NotEndsWith?: string | null,
  name?: string | null,
  name_Not?: string | null,
  name_In?: Array< string > | null,
  name_NotIn?: Array< string > | null,
  name_Lt?: string | null,
  name_Lte?: string | null,
  name_Gt?: string | null,
  name_Gte?: string | null,
  name_Contains?: string | null,
  name_NotContains?: string | null,
  name_StartsWith?: string | null,
  name_NotStartsWith?: string | null,
  name_EndsWith?: string | null,
  name_NotEndsWith?: string | null,
  alias?: string | null,
  alias_Not?: string | null,
  alias_In?: Array< string > | null,
  alias_NotIn?: Array< string > | null,
  alias_Lt?: string | null,
  alias_Lte?: string | null,
  alias_Gt?: string | null,
  alias_Gte?: string | null,
  alias_Contains?: string | null,
  alias_NotContains?: string | null,
  alias_StartsWith?: string | null,
  alias_NotStartsWith?: string | null,
  alias_EndsWith?: string | null,
  alias_NotEndsWith?: string | null,
};

export interface PaginationInput {
  page?: number | null,
  pageSize?: number | null,
  skip?: number | null,
  take?: number | null,
};

export enum PropertyOrderByInput {
  propertyCode = "propertyCode", // Order by property code
  propertyCode_Desc = "propertyCode_Desc", // Order by property code desc
  owner_Name = "owner_Name", // Order by owner
  owner_Name_Desc = "owner_Name_Desc", // Order by owner desc
  propertyType = "propertyType", // Order by property type
  propertyType_Desc = "propertyType_Desc", // Order by property type desc
  propertyStatus = "propertyStatus", // Order by property status
  propertyStatus_Desc = "propertyStatus_Desc", // Order by property status desc
  landType = "landType", // Order by land type
  landType_Desc = "landType_Desc", // Order by land type desc
  price = "price", // Order by price
  price_Desc = "price_Desc", // Order by price desc
  area = "area", // Order by area
  area_Desc = "area_Desc", // Order by area desc
  length = "length", // Order by length
  length_Area = "length_Area", // Order by length desc
  height = "height", // Order by height
  height_Desc = "height_Desc", // Order by height desc
  mBRoom = "mBRoom", // Order by master bed room 
  mBRoom_Desc = "mBRoom_Desc", // Order by master bed room desc
  sBRoom = "sBRoom", // Order by single bed room 
  sBRoom_Desc = "sBRoom_Desc", // Order by single bed room desc
  floor = "floor", // Order by floor count
  floor_Desc = "floor_Desc", // Order by floor count desc
  city_Name = "city_Name", // Order by city
  city_Name_Desc = "city_Name_Desc", // Order by city desc
  address = "address", // Order by address
  address_Desc = "address_Desc", // Order by address desc
  pricingMethod = "pricingMethod", // Order by pricing method
  pricingMethod_Desc = "pricingMethod_Desc", // Order by pricing method desc
  elevator = "elevator", // Order by elevator
  elevator_Desc = "elevator_Desc", // Order by elevator desc
  swimmingPool = "swimmingPool", // Order by swimming pool
  swimmingPool_Desc = "swimmingPool_Desc", // Order by swimming pool desc
  electricAndWaterMeter = "electricAndWaterMeter", // Order by electric and water meter availability
  electricAndWaterMeter_Desc = "electricAndWaterMeter_Desc", // Order by electric and water meter avalibility
  homeLoanAvailable = "homeLoanAvailable", // Order by home loan availibility
  homeLoanAvailable_Desc = "homeLoanAvailable_Desc", // Order by home loan availability
}


export interface GetPropertiesQueryVariables {
  where?: PropertyWhereInput | null,
  pagination?: PaginationInput | null,
  orderBy?: Array< PropertyOrderByInput | null > | null,
};

export interface GetPropertiesQuery {
  propertyQuery:  {
    properties:  {
      // Pagination info.
      pageInfo:  {
        // Current page number of result set.
        currentPage: number | null,
        // If true, next page is availabe.
        hasNextPage: boolean | null,
        // If true, previous page is available
        hasPreviousPage: boolean | null,
        // Total number of pages to be fetched
        pageCount: number | null,
        // Number of rows in a page
        pageSize: number | null,
        // Total number of rows to be fetched
        rowCount: number | null,
      },
      // Actual list of connection
      edges:  Array< {
        city:  {
          id: string | null,
          name: string,
          alias: string | null,
          region:  {
            id: string | null,
            name: string,
            alias: string | null,
          } | null,
        },
        id: string | null,
        propertyCode: string,
        about: string | null,
        price: number | null,
        propertyType: PropertyType,
        width: number | null,
        length: number | null,
        area: number | null,
        floor: number | null,
        propertyPhoto: string | null,
      } >,
    } | null,
  } | null,
};

export interface GetPropertyByIdQueryVariables {
  id: string,
};

export interface GetPropertyByIdQuery {
  propertyQuery:  {
    property:  {
      id: string | null,
      address: string,
      area: number | null,
      city:  {
        id: string | null,
        name: string,
        alias: string | null,
        region:  {
          id: string | null,
          name: string,
          alias: string | null,
        } | null,
        sortOrder: number | null,
      },
      electricAndWaterMeter: boolean,
      elevator: boolean,
      floor: number | null,
      height: number | null,
      homeLoanAvailable: boolean,
      landType: LandType,
      length: number | null,
      mBRoom: number | null,
      price: number | null,
      pricingMethod: PricingMethod,
      propertyCode: string,
      propertyType: PropertyType,
      propertyStatus: PropertyStatus,
      sBRoom: number | null,
      swimmingPool: boolean,
      width: number | null,
      propertyPhotos:  Array< {
        id: string | null,
        caption: string,
        description: string,
        sortOrder: number | null,
        url: string,
        propertyId: string,
      } | null > | null,
      activeStatus: PropertyActiveStatus,
      about: string | null,
    } | null,
  } | null,
};
