import { createAction, getType } from "typesafe-actions";
import { $Call, DeepReadonly } from "utility-types";
import update from "immutability-helper";
import { DisplayLanguage } from "../common/DisplayLanguage";
export const siteActions = {
  openSnackbar: createAction("site/open_snackbar", (resolve) => {
    return (message: string) => resolve(message);
  }),
  closeSnackbar: createAction("site/close_snackbar"),
  toggleDrawer: createAction("site/toggle_drawer"),
  setUserSession: createAction("site/setUserSession", (resolve) => {
    return (userSession: UserSession) => resolve(userSession);
  }),
  addHistory: createAction("site/addHistory", (resolve) => {
    return (url: string) => resolve(url);
  }),
  setLanguage: createAction("site/userSession/setLanguage", (resolve) => {
    return (language: DisplayLanguage) => resolve(language);
  }),
};

export type UserSession = DeepReadonly<{
  accessToken?: string;
  userId?: string;
  userName?: string;
  fullName?: string;
  userRole?: string;
  identityCode?: number;
  defaultAdmin?: boolean;
  validFrom?: Date;
  validTo?: Date;
  authenticated: boolean;
  language: DisplayLanguage;
}>;

export type SiteState = {
  readonly isSnackbarOpen: boolean;
  readonly snackbarMessage: string;
  readonly isDrawerOpen: boolean;
  readonly periodId: string;
  userSession: UserSession;
  history: ReadonlyArray<string>;
};

const initialState: SiteState = {
  isSnackbarOpen: false,
  snackbarMessage: "",
  isDrawerOpen: false,
  userSession: {
    authenticated: false,
    language: DisplayLanguage.en,
  },
  history: [],
  periodId: "",
};
export type SiteActions =
  | $Call<typeof siteActions.setUserSession>
  | $Call<typeof siteActions.openSnackbar>
  | $Call<typeof siteActions.closeSnackbar>
  | $Call<typeof siteActions.toggleDrawer>
  | $Call<typeof siteActions.addHistory>
  | $Call<typeof siteActions.setLanguage>;

export const siteReducer = (
  state: SiteState = initialState,
  action: SiteActions
) => {
  switch (action.type) {
    case getType(siteActions.openSnackbar):
      return Object.assign({}, state, {
        isSnackbarOpen: true,
        snackbarMessage: action.payload,
      });
    case getType(siteActions.closeSnackbar):
      return Object.assign({}, state, {
        isSnackbarOpen: false,
        snackbarMessage: "",
      });
    case getType(siteActions.toggleDrawer):
      return Object.assign({}, state, {
        isDrawerOpen: !state.isDrawerOpen,
      });
    case getType(siteActions.setUserSession):
      return Object.assign({}, state, {
        userSession: Object.assign({}, state.userSession, action.payload),
      });
    case getType(siteActions.addHistory):
      return update(state, {
        history: {
          $splice: [[0, 0, action.payload]],
        },
      });
    case getType(siteActions.setLanguage):
      return update(state, {
        userSession: {
          language: {
            $set: action.payload,
          },
        },
      });
    default:
      return state;
  }
};
