import { getCreatedStore } from "../createStore";
import { PropertyStatus } from "../property/types";
import { DisplayLanguage, LanguageDictionary } from "./DisplayLanguage";

const languages: { [key: string]: LanguageDictionary } = {
  [PropertyStatus.RENT]: {
    [DisplayLanguage.en]: "Rent",
    [DisplayLanguage.zg]: "အငွား",
    [DisplayLanguage.mm]: "အငှား",
    [DisplayLanguage.cn]: "出租",
  },
  [PropertyStatus.SELL]: {
    [DisplayLanguage.en]: "Sell",
    [DisplayLanguage.zg]: "အေရာင္း",
    [DisplayLanguage.mm]: "အရောင်း",
    [DisplayLanguage.cn]: "出售",
  },
  [PropertyStatus.PROJECT]: {
    [DisplayLanguage.en]: "Project",
    [DisplayLanguage.zg]: "ပေရာဂ်က္",
    [DisplayLanguage.mm]: "ပရောဂျက်",
    [DisplayLanguage.cn]: "项目",
  },
};

function propertyStatusOptions() {
  const store = getCreatedStore();
  var language = store.getState().site.userSession.language ?? DisplayLanguage.en;
  const options: {
    [key: string]: PropertyStatusOptionType;
  } = {
    [PropertyStatus.RENT]: {
      value: PropertyStatus.RENT,
      text: languages[PropertyStatus.RENT][language],
    },
    [PropertyStatus.SELL]: {
      value: PropertyStatus.SELL,
      text: languages[PropertyStatus.SELL][language],
    },
    [PropertyStatus.PROJECT]: {
      value: PropertyStatus.PROJECT,
      text: languages[PropertyStatus.PROJECT][language],
    },
  };
  return options;
}

function propertyStatusOptionList() {
    const options = propertyStatusOptions();
  return Object.keys(options).map(
    (e) => options[e]
  );
}

type PropertyStatusOptionType = {
  value: PropertyStatus;
  text: string;
};

export {
  languages as PropertyStatusLanguage,
  propertyStatusOptions,
  propertyStatusOptionList,
  PropertyStatusOptionType,
};
