import { getCreatedStore } from "../createStore";
import { PropertyType } from "../property/types";
import { DisplayLanguage, LanguageDictionary } from "./DisplayLanguage";

const languages: { [key: string]: LanguageDictionary } = {
  [PropertyType.APARTMENT]: {
    [DisplayLanguage.en]: "Apartment",
    [DisplayLanguage.zg]: "တိုုက္ခန္း",
    [DisplayLanguage.mm]: "တိုက်ခန်း",
    [DisplayLanguage.cn]: "房间",
  },
  [PropertyType.MINI_CONDO]: {
    [DisplayLanguage.en]: "Mini Condo",
    [DisplayLanguage.zg]: "မီနီကြန္ဒိုု",
    [DisplayLanguage.mm]: "မီနီကွန်ဒို",
    [DisplayLanguage.cn]: "小公寓",
  },
  [PropertyType.CONDO]: {
    [DisplayLanguage.en]: "Condo",
    [DisplayLanguage.zg]: "ကြန္ဒိုု",
    [DisplayLanguage.mm]: "ကွန်ဒို",
    [DisplayLanguage.cn]: "公寓",
  },
  [PropertyType.LAND]: {
    [DisplayLanguage.en]: "Land",
    [DisplayLanguage.zg]: "ေျမ",
    [DisplayLanguage.mm]: "မြေ",
    [DisplayLanguage.cn]: "地",
  },
  [PropertyType.HOUSING]: {
    [DisplayLanguage.en]: "Housing",
    [DisplayLanguage.zg]: "လံုုးခ်င္း",
    [DisplayLanguage.mm]: "လုံးချင်း",
    [DisplayLanguage.cn]: "别墅",
  },
  [PropertyType.ZONE]: {
    [DisplayLanguage.en]: "Zone",
    [DisplayLanguage.zg]: "စက္မွဳဇုုန္",
    [DisplayLanguage.mm]: "စက်မှုဇုန်",
    [DisplayLanguage.cn]: "工业区",
  },
  [PropertyType.COMMERCIAL]: {
    [DisplayLanguage.en]: "Commercial",
    [DisplayLanguage.zg]: "ဆိုုင္ခန္း",
    [DisplayLanguage.mm]: "ဆိုင်ခန်း",
    [DisplayLanguage.cn]: "门面",
  },
  [PropertyType.OFFICE]: {
    [DisplayLanguage.en]: "Office",
    [DisplayLanguage.zg]: "ရံုုခန္း",
    [DisplayLanguage.mm]: "ရုံခန်း",
    [DisplayLanguage.cn]: "办公室",
  },
};

function propertyTypeOptions() {
  const store = getCreatedStore();
  var language =
    store.getState().site.userSession.language ?? DisplayLanguage.en;
  const options: {
    [key: string]: PropertyTypeOptionType;
  } = {
    [PropertyType.APARTMENT]: {
      value: PropertyType.APARTMENT,
      text: languages[PropertyType.APARTMENT][language],
    },
    [PropertyType.MINI_CONDO]: {
      value: PropertyType.MINI_CONDO,
      text: languages[PropertyType.MINI_CONDO][language],
    },
    [PropertyType.CONDO]: {
      value: PropertyType.CONDO,
      text: languages[PropertyType.CONDO][language],
    },
    [PropertyType.LAND]: {
      value: PropertyType.LAND,
      text: languages[PropertyType.LAND][language],
    },
    [PropertyType.HOUSING]: {
      value: PropertyType.HOUSING,
      text: languages[PropertyType.HOUSING][language],
    },
    [PropertyType.ZONE]: {
      value: PropertyType.ZONE,
      text: languages[PropertyType.ZONE][language],
    },
    [PropertyType.COMMERCIAL]: {
      value: PropertyType.COMMERCIAL,
      text: languages[PropertyType.COMMERCIAL][language],
    },
    [PropertyType.OFFICE]: {
      value: PropertyType.OFFICE,
      text: languages[PropertyType.OFFICE][language],
    },
  };
  return options;
}

function propertyTypeOptionList() {
  const options = propertyTypeOptions();
  return Object.keys(options).map((e) => options[e]);
}

type PropertyTypeOptionType = {
  value: PropertyType;
  text: string;
};

export {
  languages as PropertyTypeLanguages,
  propertyTypeOptions ,
  propertyTypeOptionList ,
  PropertyTypeOptionType ,
};
