import gql from "graphql-tag";

const getPropertiesQuery = gql`
query GetProperties($where:PropertyWhereInput,$pagination:PaginationInput, $orderBy:[PropertyOrderByInput] ){
  propertyQuery{
    properties(where:$where,pagination:$pagination, orderBy:$orderBy){
      pageInfo{
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        pageSize
        rowCount
      }
      edges{
        city{
          id
          name
          alias
          region{
            id
            name
            alias
          }
        }
        id
        propertyCode
        about
        price
        propertyType
        width
        length
        area
        floor
        propertyPhoto
      }
    }
  }
}
`;
const getPropertyByIdQuery = gql`
  query GetPropertyById($id: ID!) {
    propertyQuery {
      property(id: $id) {
        id
        address
        area
        city {
          id
          name
          alias
          region {
            id
            name
            alias
          }
          sortOrder
        }
        electricAndWaterMeter
        elevator
        floor
        height
        homeLoanAvailable
        landType
        length
        mBRoom
        price
        pricingMethod
        propertyCode
        propertyType
        propertyStatus
        sBRoom
        swimmingPool
        width
        propertyPhotos {
          id
          caption
          description
          sortOrder
          url
          propertyId
        }
        activeStatus
        about
      }
    }
  }
`;

export {getPropertyByIdQuery, getPropertiesQuery};