import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {
  DisplayLanguage,
  LanguageDefination,
  LanguageText,
} from "../common/DisplayLanguage";
import ExpandMore from "@material-ui/icons/ExpandMore";
import qs from "query-string";
import { useQuery } from "react-apollo";
import {
  CityFindByIdQuery,
  CityFindByIdQueryVariables,
  RegionFindAllQuery,
  RegionFindAllQueryVariables,
  RegionOrderBy,
} from "./types";
import { cityFindByIdQuery, regionFindAllQuery } from "./graphql";
import Badge from "@material-ui/core/Badge/Badge";
import LocationOn from "@material-ui/icons/LocationOn";
import { useHistory, useLocation } from "react-router-dom";
import { CitySelector } from "./CitySelector";
import { CityGraphType } from "./resolvedTypes";
export default function RegionSelector() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const handleSelect = React.useCallback((language: DisplayLanguage) => {
    handleClose();
  }, []);

  const location = useLocation();
  const params = React.useMemo(() => {
    return qs.parse(location.search, { arrayFormat: "comma" });
  }, [location.search]);

  const paramCity = React.useMemo(() => {
    if (typeof params.city == "string") {
      return [params.city];
    } else if (params.city) {
      return params.city as string[];
    } else {
      return [];
    }
  }, [params]);

  const { data: regionQueryResult } = useQuery<
    RegionFindAllQuery,
    RegionFindAllQueryVariables
  >(regionFindAllQuery, {
    variables: {
      pagination: {
        pageSize: 1000,
      },
      orderBy: [RegionOrderBy.sortOrder],
    },
  });

  const { data: cityByIdQueryResult } = useQuery<
    CityFindByIdQuery,
    CityFindByIdQueryVariables
  >(cityFindByIdQuery, {
    variables: {
      id: paramCity[0],
    },
    skip: paramCity.length == 0,
  });

  const text = React.useMemo(() => {
    const selectedCity = cityByIdQueryResult?.cityQuery?.city;
    if (paramCity.length == 0 || !selectedCity)
      return <LanguageText text={{ en: "Township", mm: "မြို့နယ်" }} />;
    else {
      return (
        <LanguageText
          text={{ en: selectedCity.name, mm: selectedCity.alias }}
        />
      );
    }
  }, [cityByIdQueryResult, paramCity]);

  const [regionId, setRegionId] = React.useState("");

  const menuItems = React.useMemo(() => {
    const regions = regionQueryResult?.regionQuery?.regions?.edges;
    if (!regions) return null;
    return regions.map((region) => (
      <MenuItem
        key={region.id}
        onClick={() => {
          setRegionId(region.id);
        }}
      >
        <LanguageText
          useSkeleton
          text={{ en: region.name, mm: region.alias }}
        />
      </MenuItem>
    ));
  }, [regionQueryResult]);

  const handleCitiesSelected = React.useCallback(
    (cities: CityGraphType[]) => {
      handleClose();
      window.scrollTo(0, 0);
      setRegionId("");
      history.push(
        qs.stringifyUrl(
          {
            url: history.location.pathname,
            query: { ...params, city: cities.map((c) => c.id) },
          },
          { arrayFormat: "comma" }
        )
      );
    },
    [params, handleClose]
  );

  return (
    <div>
      <Badge
        color="secondary"
        badgeContent={paramCity.length == 0 ? null : paramCity.length}
      >
        <Button
          startIcon={<LocationOn />}
          aria-controls="region-menu"
          aria-haspopup="true"
          onClick={handleClick}
          endIcon={<ExpandMore />}
        >
          {text}
        </Button>
      </Badge>
      <Menu
        id="region-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorEl"
      >
        <MenuItem
          onClick={() => {
            handleClose();
            window.scrollTo(0, 0);
            setRegionId("");
            history.push(
              qs.stringifyUrl(
                {
                  url: history.location.pathname,
                  query: { ...params, city: null },
                },
                { arrayFormat: "comma" }
              )
            );
          }}
        >
          <LanguageText useSkeleton text={LanguageDefination.all} />
        </MenuItem>
        {menuItems}
      </Menu>
      <CitySelector
        open={!!regionId}
        regionId={regionId}
        onClose={() => {
          setRegionId(null);
        }}
        onSelected={handleCitiesSelected}
      />
    </div>
  );
}
