import accounting from "accounting";
import * as React from "react";
import { useSelector } from "react-redux";
import { PropertyType } from "../property/types";
import { RootState } from "../reducer";
import MMText from "./MMText";
export enum DisplayLanguage {
  en = "en",
  mm = "mm",
  zg = "zg",
  cn = "cn",
}

export const LanguageDefination = {
  all: {
    [DisplayLanguage.en]: "All",
    [DisplayLanguage.mm]: "အားလုံး",
    [DisplayLanguage.zg]: "အားလံုုး",
    [DisplayLanguage.cn]: "所有",
  },
  language: {
    [DisplayLanguage.en]: { alias: "EN", name: "ENGLISH" },
    [DisplayLanguage.mm]: { alias: "ယူနီကုတ်", name: "UNICODE" },
    [DisplayLanguage.zg]: { alias: "ေဇာ္ဂ်ီ", name: "ZAW GYI" },
    [DisplayLanguage.cn]: { alias: "中文", name: "CHINESE" },
  },
  ft: {
    [DisplayLanguage.en]: "ft",
    [DisplayLanguage.mm]: "ပေ",
    [DisplayLanguage.zg]: "ေပ",
    [DisplayLanguage.cn]: "ft",
  },
  currencyDecimal: {
    [DisplayLanguage.en]: "M",
    [DisplayLanguage.mm]: "သိန်း",
    [DisplayLanguage.zg]: "သိန္း",
    [DisplayLanguage.cn]: ["万", "亿"],
  },
  floor: {
    [DisplayLanguage.en]: "Floor",
    [DisplayLanguage.mm]: "လွှာ",
    [DisplayLanguage.zg]: "လြွာ",
    [DisplayLanguage.cn]: "floor",
  },
  stoery: {
    [DisplayLanguage.en]: "Stoery",
    [DisplayLanguage.mm]: "ထပ်",
    [DisplayLanguage.zg]: "ထပ္",
    [DisplayLanguage.cn]: "stoery",
  },
  groundFloor: {
    [DisplayLanguage.en]: "Ground floor",
    [DisplayLanguage.mm]: "မြေညီ",
    [DisplayLanguage.zg]: "ေျမညီ",
    [DisplayLanguage.cn]: "ground floor",
  },
  firstFloor: {
    [DisplayLanguage.en]: "1st floor",
    [DisplayLanguage.mm]: "ပထမထပ်",
    [DisplayLanguage.zg]: "ပထမထပ္",
    [DisplayLanguage.cn]: "first floor",
  },
  secondFloor: {
    [DisplayLanguage.en]: "2nd floor",
    [DisplayLanguage.mm]: "မြေညီ",
    [DisplayLanguage.zg]: "ဒုတိယထပ်",
    [DisplayLanguage.cn]: "second floor",
  },
  thirdFloor: {
    [DisplayLanguage.en]: "3rd floor",
    [DisplayLanguage.mm]: "တတိယထပ်",
    [DisplayLanguage.zg]: "တတိယထပ္",
    [DisplayLanguage.cn]: "third floor",
  },
  dimension: {
    [DisplayLanguage.en]: "Dimension",
    [DisplayLanguage.zg]: "အက်ယ္အဝန္း",
    [DisplayLanguage.mm]: "အကျယ်အဝန်း",
    [DisplayLanguage.cn]: "Dimension",
  },
  height: {
    [DisplayLanguage.en]: "Height",
    [DisplayLanguage.mm]: "အမြင့်",
    [DisplayLanguage.zg]: "အျမင့္",
    [DisplayLanguage.cn]: "Height",
  },
  masterBedRoom: {
    [DisplayLanguage.en]: "Master Bed Room",
    [DisplayLanguage.mm]: "အိပ်ခန်း",
    [DisplayLanguage.zg]: "အိပ္ခန္း",
    [DisplayLanguage.cn]: "Master Bed Room",
  },
  singleBedRoom: {
    [DisplayLanguage.en]: "Single Bed Room",
    [DisplayLanguage.mm]: "တစ်ယောက်အိပ်ခန်း",
    [DisplayLanguage.zg]: "တစ္ေယာက္အိပ္ခန္း",
    [DisplayLanguage.cn]: "Single Bed Room",
  },
  elevator: {
    [DisplayLanguage.en]: "Elevator",
    [DisplayLanguage.mm]: "ဓါတ်လှေခါး",
    [DisplayLanguage.zg]: "ဓါတ္ေလွခါး",
    [DisplayLanguage.cn]: "Elevator",
  },
  swimmingPool: {
    [DisplayLanguage.en]: "Swimming Pool",
    [DisplayLanguage.mm]: "ရေကူးကန်",
    [DisplayLanguage.zg]: "ေရကူးကန္",
    [DisplayLanguage.cn]: "Swimming Pool",
  },
  electricAndWater: {
    [DisplayLanguage.en]: "Electric & Water",
    [DisplayLanguage.mm]: "ရေ/မီး မီတာ",
    [DisplayLanguage.zg]: "ေရ/မီး မီတာ",
    [DisplayLanguage.cn]: "Electric & Water",
  },
  loanAvailability: {
    [DisplayLanguage.en]: "Installment",
    [DisplayLanguage.mm]: "အရစ်ကျ",
    [DisplayLanguage.zg]: "အရစ္က်",
    [DisplayLanguage.cn]: "Installment",
  },
  landType: {
    [DisplayLanguage.en]: "Land Type",
    [DisplayLanguage.mm]: "မြေအမျိုးအစား",
    [DisplayLanguage.zg]: "ေျမအမ်ိဳးအစား",
    [DisplayLanguage.cn]: "Land Type",
  },
  floorOrStoery: {
    [DisplayLanguage.en]: "Floor/Stoery",
    [DisplayLanguage.mm]: "အလွှာ/အထပ်",
    [DisplayLanguage.zg]: "အလြွာ/အထပ္",
    [DisplayLanguage.cn]: "Floor/Stoery",
  },
};

export const formatCurrency = (
  amount: number,
  language: DisplayLanguage,
  includeCurrency: boolean = true
) => {
  let result = "";
  let currency = "";
  switch (language) {
    case DisplayLanguage.mm:
    case DisplayLanguage.zg:
      result = accounting.formatNumber(amount / 100000, 0);
      currency = LanguageDefination.currencyDecimal[language];
      break;
    case DisplayLanguage.cn:
      if (amount > 100000000) {
        result = accounting.formatNumber(amount / 100000000, 3);
        currency = LanguageDefination.currencyDecimal[DisplayLanguage.cn][1];
      } else {
        result = accounting.formatNumber(amount / 10000, 0);
        currency = LanguageDefination.currencyDecimal[DisplayLanguage.cn][0];
      }
      break;
    default:
      result = accounting.formatNumber(amount / 1000000, 1);
      currency = LanguageDefination.currencyDecimal[language];
      break;
  }
  if (includeCurrency) return `${result} ${currency}`;
  else return result;
};

export const formatArea = (
  language: DisplayLanguage,
  width: number = 0,
  length: number = 0,
  area: number = 0
): string => {
  let result: string = "";
  if (width && length) {
    result = `${width} ${LanguageDefination.ft[language]} x ${length} ${LanguageDefination.ft[language]}`;
  } else if (area) {
    if (result) result = `${result} = `;
    result = `${result}${area} ${LanguageDefination.ft[language]}\u00B2`;
  }
  return result;
};
export type LanguageTextProps = {
  text: {
    en: string;
    mm?: string;
    cn?: string;
  };
  skeletonWidth?: number;
  useSkeleton?: boolean;
  prefix?: string;
  postfix?: string;
};
export function LanguageText({
  text,
  skeletonWidth,
  useSkeleton,
  prefix,
  postfix,
}: LanguageTextProps) {
  const language = useSelector(
    (state: RootState) => state.site.userSession.language
  );
  switch (language) {
    case DisplayLanguage.zg:
      return (
        <MMText skeletonWidth={skeletonWidth} useSkeleton={useSkeleton}>{`${
          prefix ?? ""
        }${text.mm ?? text.en}${postfix ?? ""}`}</MMText>
      );
    default:
      return (
        <>{`${prefix ?? ""}${text[language] ?? text.en}${postfix ?? ""}`}</>
      );
  }
}

export function formatFloor(
  floor: number,
  type: PropertyType,
  language: DisplayLanguage
) {
  switch (type) {
    case PropertyType.APARTMENT:
    case PropertyType.MINI_CONDO:
    case PropertyType.CONDO:
      if (floor == 0) {
        return LanguageDefination.groundFloor[language];
      } else if (floor == 1) {
        return LanguageDefination.firstFloor[language];
      } else if (floor == 2) {
        return LanguageDefination.secondFloor[language];
      } else if (floor == 3) {
        return LanguageDefination.thirdFloor[language];
      } else if (floor > 3) {
        return `${floor} ${LanguageDefination.floor[language]}`;
      } else {
        return "";
      }
      break;
    case PropertyType.HOUSING:
      if (floor) return `${floor} ${LanguageDefination.stoery[language]}`;
      else return "";
      break;
    default:
      return "";
      break;
  }
}

export type LanguageDictionary = { [key: string]: string };
