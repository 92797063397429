import * as React from "react";
import { useQuery } from "react-apollo";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  CellRenderer,
  createMasonryCellPositioner,
  Masonry,
  Positioner,
  Size,
  WindowScroller,
  WindowScrollerChildProps,
} from "react-virtualized";
import { getPropertiesQuery } from "./graphql";
import {
  GetPropertiesQuery,
  GetPropertiesQueryVariables,
  PropertyOrderByInput,
  PropertyStatus,
  PropertyType,
  PropertyWhereInput,
} from "./types";
import PropertyItemCard from "./PropertyItemCard";
import { PropertyItemType } from "./resolvedTypes";
import { useLocation, useRouteMatch } from "react-router-dom";
import qs from "query-string";
import update from "immutability-helper";
import EmptyPropertyItemCard from "./EmptyPropertyItemCard";
import { NetworkStatus } from "apollo-client";
import { isSSR } from "../utility";
import ScreenInfoContext from "../common/ScreenInfoContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
const defaultColumnWidth = 300;
const overscanPixel = 300;
const useStyles = makeStyles((theme) => ({
  masonry: {
    padding: theme.spacing(0.5),
    paddingTop:
      isSSR || window.screen.width < 600 ? theme.spacing(1) : theme.spacing(2),
    outline: "none",
  },
}));

function PropertyMasonry() {
  const screenInfo = React.useContext(ScreenInfoContext);
  const pageSize = React.useMemo(() => {
    return screenInfo.width < 600 ? 30 : 80;
  }, [screenInfo.width]);
  const classes = useStyles();
  const location = useLocation();
  const {
    params: { status },
  } = useRouteMatch<{ status: PropertyStatus }>();
  const params = React.useMemo(() => {
    return qs.parse(location.search, { arrayFormat: "comma" });
  }, [location.search]);
  const paramPrpertyType = React.useMemo(() => {
    if (typeof params.type == "string") {
      return [params.type as PropertyType];
    } else if (params.type) {
      return params.type as PropertyType[];
    } else {
      return [];
    }
  }, [params]);
  const paramCity = React.useMemo(() => {
    if (typeof params.city == "string") {
      return [params.city];
    } else if (params.city) {
      return params.city as string[];
    } else {
      return [];
    }
  }, [params]);
  const variables: GetPropertiesQueryVariables = React.useMemo(() => {
    const where: PropertyWhereInput = {};
    if (paramPrpertyType.length) {
      where.propertyType_In = paramPrpertyType;
    }
    if (status) {
      where.propertyStatus = status;
    }
    if (paramCity.length) {
      where.city = { id_In: paramCity };
    }
    return {
      pagination: {
        pageSize,
      },
      where,
      orderBy: [PropertyOrderByInput.propertyCode],
    };
  }, [paramPrpertyType, status, paramCity]);

  const { data: queryResult, networkStatus, fetchMore } = useQuery<
    GetPropertiesQuery,
    GetPropertiesQueryVariables
  >(getPropertiesQuery, {
    variables,
    ssr: false,
  });

  React.useEffect(() => {
    if (
      networkStatus == NetworkStatus.loading ||
      networkStatus == NetworkStatus.setVariables
    ) {
      return () => {
        window.scrollTo(0, 0);
      };
    }
  }, [networkStatus]);

  const loadMoreRows = React.useCallback(
    (index: number) => {
      const page = Math.round(index / pageSize + 1);
      if (!loadingJobs.current[page]) {
        const job = fetchMore({
          variables: update(variables, {
            pagination: {
              page: {
                $set: page,
              },
            },
          }),
          updateQuery: (previousResult, { fetchMoreResult }) => {
            if (!fetchMoreResult?.propertyQuery?.properties)
              return previousResult;
            const {
              edges: moreEdges,
              pageInfo,
            } = fetchMoreResult.propertyQuery.properties;
            return update(previousResult, {
              propertyQuery: {
                properties: {
                  edges: {
                    $push: moreEdges,
                  },
                  pageInfo: {
                    $set: pageInfo,
                  },
                },
              },
            });
          },
        }).then((result) => result?.data?.propertyQuery?.properties?.edges);
        loadingJobs.current[page] = job;
        return job
          .then((result) => {
            delete loadingJobs.current[page];
            return result;
          })
          .catch((e) => {
            delete loadingJobs.current[page];
          });
      } else {
        return loadingJobs.current[page];
      }
    },
    [fetchMore, variables]
  );

  const theme = useTheme();

  const columnWidth = React.useRef(defaultColumnWidth);

  const propertyConnection = queryResult?.propertyQuery?.properties;
  const properties = propertyConnection?.edges ?? [];
  const pageInfo = propertyConnection?.pageInfo;

  const columnCount = React.useRef(0);
  const cache = React.useRef(
    new CellMeasurerCache({
      defaultHeight: 320,
      defaultWidth: columnWidth.current,
      fixedWidth: true,
    })
  );
  const calculateColumnCount = React.useCallback((screenWidth: number) => {
    if (screenWidth <= 600) {
      columnWidth.current = screenWidth / 2 - theme.spacing(0.5);
      columnCount.current = 2;
    } else {
      columnWidth.current = defaultColumnWidth;
      columnCount.current = Math.floor(screenWidth / columnWidth.current);
    }
  }, []);

  const masonry = React.useRef<Masonry>(null);

  const loadingJobs = React.useRef<{
    [id: number]: Promise<PropertyItemType[]>;
  }>({});

  const cellPositioner = React.useRef<Positioner>(null);

  const resetCellPositioner = React.useCallback(() => {
    cellPositioner.current.reset({
      columnCount: columnCount.current,
      columnWidth: columnWidth.current,
      spacer: 0,
    });
  }, []);

  const onResize = React.useCallback(({ width }: Size) => {
    calculateColumnCount(width);
    resetCellPositioner();
    masonry.current.recomputeCellPositions();
  }, []);

  const cellRenderer = React.useCallback<CellRenderer>(
    ({ index, key, parent, style }) => {
      const property = properties[index];
      return (
        <CellMeasurer
          cache={cache.current}
          index={index}
          key={key}
          parent={parent}
        >
          {property ? (
            <PropertyItemCard
              data={property}
              style={{ ...style, width: columnWidth.current }}
            />
          ) : (
            <EmptyPropertyItemCard
              style={{ ...style, width: columnWidth.current }}
            />
          )}
        </CellMeasurer>
      );
    },
    [properties]
  );

  const resetList = React.useCallback(() => {
    if (cellPositioner.current && masonry.current) {
      cache.current.clearAll();
      resetCellPositioner();
      masonry.current.clearCellPositions();
    }
  }, []);

  React.useEffect(resetList, [properties]);

  const renderMasonry = React.useCallback(
    ({ width, height }: Size, scrollTop: number) => {
      calculateColumnCount(width);
      const mansonaryWidth =
        columnCount.current * columnWidth.current + theme.spacing(1);
      const marginLeft =
        columnWidth.current < defaultColumnWidth
          ? 0
          : (width - mansonaryWidth) / 2;
      if (cellPositioner.current == null) {
        cellPositioner.current = createMasonryCellPositioner({
          cellMeasurerCache: cache.current,
          columnCount: columnCount.current,
          columnWidth: columnWidth.current,
          spacer: 0,
        });
      }
      return (
        <Masonry
          overscanByPixels={overscanPixel}
          className={classes.masonry}
          style={{ marginLeft: marginLeft }}
          ref={masonry}
          cellCount={pageInfo.rowCount}
          cellMeasurerCache={cache.current}
          cellPositioner={cellPositioner.current}
          cellRenderer={cellRenderer}
          height={height}
          width={mansonaryWidth + marginLeft}
          autoHeight={true}
          scrollTop={scrollTop}
          onCellsRendered={({ startIndex, stopIndex }) => {
            if (stopIndex && !properties[stopIndex]) {
              loadMoreRows(stopIndex);
            }
          }}
        />
      );
    },
    [pageInfo, cellRenderer]
  );

  const renderAutoSizer = React.useCallback(
    ({ height, scrollTop }: WindowScrollerChildProps) => {
      return (
        <AutoSizer
          disableHeight
          height={height}
          defaultWidth={screenInfo.width}
          onResize={onResize}
          scrollTop={scrollTop}
        >
          {({ width }) => renderMasonry({ width, height }, scrollTop)}
        </AutoSizer>
      );
    },
    [renderMasonry]
  );

  if (properties.length == 0) return null;
  return (
    <WindowScroller serverHeight={screenInfo.height}>
      {renderAutoSizer}
    </WindowScroller>
  );
}

export default PropertyMasonry;
