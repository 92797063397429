import makeStyles from "@material-ui/core/styles/makeStyles";
import * as React from "react";
import { useSelector } from "react-redux";
import { propertyStatusOptions } from "../common/propertyStatusOptions";
import { RootState } from "../reducer";
import { PropertyStatus } from "./types";
export type PropertyStatusBadgeProps = {
  propertyStatus: PropertyStatus;
};
const useStyles = makeStyles((theme) => {
  return {
    root: {
      padding: theme.spacing(0.5),
      borderRadius: theme.spacing(0.5),
      margin: theme.spacing(0.5),
      color: "#fff",
    },
  };
});
function PropertyStatusBadge({ propertyStatus }: PropertyStatusBadgeProps) {
  const classes = useStyles();
  const language = useSelector(
    (state: RootState) => state.site.userSession.language
  );

  const text = React.useMemo(() => {
    if (propertyStatus)
      return propertyStatusOptions()[propertyStatus].text.toUpperCase();
    else return null;
  }, [language, propertyStatus]);
  const style = React.useMemo(() => {
    let color = "#4caf50";
    switch (propertyStatus) {
      case PropertyStatus.RENT:
        color = "#4caf50";
        break;
      case PropertyStatus.SELL:
        color = "#9c27b0";
        break;
      case PropertyStatus.PROJECT:
        color = "#3f51b5";
        break;
    }
    const style: React.CSSProperties = {
      backgroundColor: color,
    };
    return style;
  }, [propertyStatus]);

  return (
    <span className={classes.root} style={style}>
      {text}
    </span>
  );
}

export default PropertyStatusBadge;
