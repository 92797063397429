import { loadingBarReducer } from "react-redux-loading-bar";
import { combineReducers } from "redux";
import { SiteActions, SiteState, siteReducer } from "./site";

export type RootAction = SiteActions;

export type AsyncDispatch = {
  asyncDispatch?: (asyncAction: RootAction) => void;
};

interface StoreEnhancerState {}

export interface RootState extends StoreEnhancerState {
  site: SiteState;
  loadingBar: any;
}

export const rootReducer = combineReducers<RootState>({
  loadingBar: loadingBarReducer,
  site: siteReducer
});
