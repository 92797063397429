import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { DisplayLanguage, LanguageDefination } from "../common/DisplayLanguage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../reducer";
import { siteActions } from "../reducer/site";
import ExpandMore from "@material-ui/icons/ExpandMore";
export default function LanguageSelector() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const selectedLanguage = useSelector(
    (state: RootState) => state.site.userSession.language
  );
  const dispatch = useDispatch();
  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, [setAnchorEl]);
  const handleSelect = React.useCallback((language: DisplayLanguage) => {
    dispatch(siteActions.setLanguage(language));
    handleClose();
  }, []);

  const text = React.useMemo(() => {
    if (!selectedLanguage) return "Language";
    const { alias, name } = LanguageDefination.language[selectedLanguage];
    return alias;
  }, [selectedLanguage]);

  const languages = React.useMemo(() => {
    return Object.entries(LanguageDefination.language).map(
      ([key, { alias, name }]) => (
        <MenuItem
          key={key}
          onClick={() => {
            handleSelect(key as DisplayLanguage);
          }}
        >{`${alias} - ${name}`}</MenuItem>
      )
    );
  }, []);

  return (
    <div>
      <Button
        aria-controls="language-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ExpandMore/>}
      >
        {text}
      </Button>
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference = "anchorEl"
      >
        {languages}
      </Menu>
    </div>
  );
}
