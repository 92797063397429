import makeStyles from "@material-ui/core/styles/makeStyles";
import * as React from "react";
import { LanguageDefination, LanguageText } from "../common/DisplayLanguage";

const useStyles = makeStyles((theme)=>({
    root:{
        padding: theme.spacing(.5), 
        borderRadius:theme.spacing(.5),
        margin: theme.spacing(.5),
        color:"#2e7d32",
        borderWidth:1,
        borderStyle:"solid",
        borderColor:"#2e7d32",
        fontSize:".7em"
    }
}));
function BankLoanAvailavilityBadge({value}:{value:boolean}){
    const classes = useStyles();
    if(!value)
        return null;
    return <span className={classes.root}><LanguageText text={LanguageDefination.loanAvailability}/></span>
}

export default BankLoanAvailavilityBadge;