function CancellablePromise<T>(promise: Promise<T>) {
  var cancellationToken:number = null;
  const ret: { cancel: (token:number) => void; promise: Promise<T> } = {
    cancel: null,
    promise: null,
  };
  ret.cancel = (token) => {
    cancellationToken = token;
  };

  ret.promise = promise.then((value) =>
    cancellationToken || cancellationToken ==0 ? Promise.reject(cancellationToken) : value
  );
  return ret;
}

export default CancellablePromise;
