import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as React from "react";
import ReactDOM from "react-dom";
import { isSSR } from "../utility";

export type SimpleLoadingIndicatorProps = { zIndex?: number };
const portalRoot = !isSSR ? document.getElementById("portal") : null;
const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    margin: "auto",
    top: "50%",
    left: "50%",
    marginTop: -20,
    marginLeft: -20,
    zIndex: 9999,
  },
}));
function SimpleLoadingIndicator({ zIndex }: SimpleLoadingIndicatorProps) {
  const container = React.useRef<Element>(null);
  React.useEffect(() => {
    container.current = document.createElement("div");
    if (portalRoot) {
      portalRoot.appendChild(container.current);
      return () => {
        portalRoot.removeChild(container.current);
      };
    }
  }, []);
  const classes = useStyles();
  if (container.current)
    return ReactDOM.createPortal(
      <CircularProgress
        className={classes.root}
        style={zIndex ? { zIndex } : null}
      />,
      container.current
    );
  else return null;
}

export default SimpleLoadingIndicator;
