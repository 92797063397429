import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const theme = createMuiTheme({
  palette: {
    type: "light",
  },
  typography: {
    // h1: {
    //   lineHeight: 1.8,
    // },
    // h2: {
    //   lineHeight: 1.8,
    // },
    // h3: {
    //   lineHeight: 1.8,
    // },
    h4: {
      lineHeight: 1.8,
    },
    h5: {
      lineHeight: 1.8,
    },
    h6: {
      lineHeight: 1.8,
    },
    subtitle1: {
      lineHeight: 1.8,
    },
    subtitle2: {
      lineHeight: 1.8,
    },
    body1: {
      lineHeight: 1.8,
    },
    body2: {
      lineHeight: 1.8,
    },
    caption: {
      lineHeight: 1.8,
    },
  },
});

export default theme;
