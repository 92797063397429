import gql from "graphql-tag";
const regionFindAllQuery = gql`
query RegionFindAll($where:RegionWhereInput,$pagination:PaginationInput, $orderBy:[RegionOrderBy]){
regionQuery{
  regions(where:$where,pagination:$pagination,orderBy:$orderBy){
    pageInfo{
      currentPage
      hasNextPage
      hasPreviousPage
      pageCount
      pageSize
      rowCount
    }
    edges{
      id
      alias
      name
      sortOrder
    }
  }
}
}
`;

const cityFindByIdQuery = gql`
query CityFindById($id:ID!){
  cityQuery{
    city(id:$id){
      id
      alias
      name
    }
  }
}
`;

const cityFindAllQuery = gql`
query CityFindAll($where:CityWhereInput,$pagination:PaginationInput, $orderBy:[CityOrderBy]){
  cityQuery{
    cities(where:$where,pagination:$pagination,orderBy:$orderBy){
      pageInfo{
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        pageSize
        rowCount
      }
      edges{
        id
        alias
        name
        region{
          id
          alias
          name
        }
      }
    }
  }
}
`;

export {regionFindAllQuery, cityFindAllQuery,cityFindByIdQuery}
