import makeStyles from "@material-ui/core/styles/makeStyles";
import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { propertyTypeOptionList } from "../common/propertyTypeOptions";
import { RootState } from "../reducer";
import qs from "query-string";
import { PropertyType } from "./types";
import Chip from "@material-ui/core/Chip/Chip";
import CheckCircle from "@material-ui/icons/CheckCircle";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import update from "immutability-helper";
import {  LanguageDefination } from "../common/DisplayLanguage";
const useStyles = makeStyles((theme) => ({
  chipScroller: {
    overflowX: "scroll",
    whiteSpace: "nowrap",
    marginBottom: -20,
    paddingBottom: 20,
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    height: 48,
    overflow: "hidden",
  },
  chip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    direction: "ltr",
  },
  offset:{
    display:"inline-block",
    width: theme.spacing(1)
  }
}));
export type PropertyTypeChipsProps = {style?: React.CSSProperties};
function PropertyTypeChips({
  forwardedRef,
  style
}: PropertyTypeChipsProps & {
  forwardedRef?: React.LegacyRef<HTMLDivElement>;
}) {
  const classes = useStyles({});
  const location = useLocation();
  const history = useHistory();
  const params = React.useMemo(() => {
    return qs.parse(location.search, { arrayFormat: "comma" });
  }, [location.search]);
  const paramPrpertyType = React.useMemo(() => {
    if (typeof params.type == "string") {
      return [params.type as PropertyType];
    } else if (params.type) {
      return params.type as PropertyType[];
    } else {
      return [];
    }
  }, [params]);
  const language = useSelector(
    (state: RootState) => state.site.userSession.language
  );
  const propertyTypeOptions = React.useMemo(() => propertyTypeOptionList(), [
    language,
  ]);
  const selectedPropertyTypes = paramPrpertyType ?? [];

  const selectedAll = React.useMemo(() => selectedPropertyTypes.length == 0, [
    selectedPropertyTypes,
  ]);
  const chips = React.useMemo(() => {
    return propertyTypeOptions.map(({ value, text }) => (
      <Chip
        color={
          selectedPropertyTypes.indexOf(value) > -1 ? "primary" : "default"
        }
        icon={
          selectedPropertyTypes.indexOf(value) > -1 ? (
            <CheckCircle />
          ) : (
            <CheckCircleOutline />
          )
        }
        onClick={(e) => {
          window.scrollTo(0,0);
          var newPropertyTypes: PropertyType[] = [];
          const selectedIndex = selectedPropertyTypes.indexOf(value);
          if (selectedPropertyTypes.indexOf(value) > -1) {
            newPropertyTypes = update(selectedPropertyTypes, {
              $splice: [[selectedIndex, 1]],
            });
          } else {
            newPropertyTypes = update(selectedPropertyTypes, {
              $push: [value],
            });
          }
          history.replace(
            qs.stringifyUrl(
              {
                url: history.location.pathname,
                query: { ...params, type: newPropertyTypes },
              },
              { arrayFormat: "comma" }
            )
          );
        }}
        key={value}
        label={text}
        className={classes.chip}
      />
    ));
  }, [propertyTypeOptions, selectedPropertyTypes]);
  return (
    <div ref={forwardedRef} className={classes.root} style={style}>
      <div className={classes.chipScroller}>
        <div className={classes.offset}/>
        <Chip
          color={selectedAll ? "secondary" : "default"}
          icon={selectedAll ? <CheckCircle /> : <CheckCircleOutline />}
          label={LanguageDefination.all[language]}
          className={classes.chip}
          onClick={() => {
            if (!selectedAll) {
              window.scrollTo(0,0);
              history.replace(
                qs.stringifyUrl(
                  {
                    url: history.location.pathname,
                    query: { ...params, type: null },
                  },
                  { arrayFormat: "comma" }
                )
              );
            }
          }}
        />
        {chips}
        <div className={classes.offset}/>
      </div>
    </div>
  );
}

export default React.forwardRef<HTMLDivElement, PropertyTypeChipsProps>(
  (props, ref) => <PropertyTypeChips {...props} forwardedRef={ref} />
);
