import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import * as React from "react";
import { PropertyItemType } from "./resolvedTypes";
import settings from "../settings";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import { useSelector } from "react-redux";
import { propertyTypeOptions } from "../common/propertyTypeOptions";
import { RootState } from "../reducer";
import {
  formatArea,
  formatCurrency,
  formatFloor,
  LanguageText,
} from "../common/DisplayLanguage";
import Grid from "@material-ui/core/Grid/Grid";
import { Language } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  fullSpacing: {
    padding: theme.spacing(1),
  },
  halfSpacing: {
    padding: theme.spacing(0.5),
  },
  card: {
    width: "100%",
  },
  cartContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  media: {
    height: 200,
    position:"relative"
  },
  area:{
    bottom:0,
    left:0,
    right:0,
    backgroundColor:"rgba(0,0,0,.6)",
    color:"#fff",
    position:"absolute",
    textAlign:"center",
    paddingTop:4,
    paddingBottom:4
  }
}));
export type PropertyItemCardProps = {
  data: PropertyItemType;
  style: React.CSSProperties;
};
function PropertyItemCard({ data, style }: PropertyItemCardProps) {
  const classes = useStyles({});
  const {
    propertyPhoto,
    city,
    propertyType,
    width,
    length,
    area,
    price,
    floor,
    propertyCode,
    id:propertyId
  } = data;
  const language = useSelector(
    (state: RootState) => state.site.userSession.language
  );
  const propertyTypes = React.useMemo(() => propertyTypeOptions(), [language]);
  const areaText = React.useMemo(() => {
    return formatArea(language, width, length, area);
  }, [language, width, length, area]);
  const priceText = React.useMemo(() => {
    return formatCurrency(price, language);
  }, [language, price]);
  const floorText = React.useMemo(()=>{
    const floorText = formatFloor(floor,propertyType,language);
    return floorText? ` - ${floorText}` : "";
  }, [floor,language,propertyType]);
  const history = useHistory();
  return (
    <div
      className={style.width < 300 ? classes.halfSpacing : classes.fullSpacing}
      style={style}
    >
      <Card className={classes.card} onClick={()=>{history.push(`/property/${propertyId}`)}}>
        <CardMedia
          image={`${settings.cloudfront_url}${propertyPhoto}`}
          className={classes.media}
        >
          <Typography variant="body2" className={classes.area}>{areaText}</Typography>
        </CardMedia>
        <ListItem>
          <ListItemText
            secondary={
              <>
                <LanguageText
                  useSkeleton={false}
                  text={{ en: city?.name, mm: city?.alias }}
                />
                /
                <LanguageText
                  useSkeleton={false}
                  text={{ en: city?.region?.name, mm: city?.region.alias }}
                />
              </>
            }
            primary={`${propertyTypes[propertyType]?.text}${floorText}`}
          />
        </ListItem>
        <Grid container className={classes.cartContent}>
          <Grid item xs={12} >
            <Typography align="right" variant="subtitle1">
              {priceText}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default PropertyItemCard;
