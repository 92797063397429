import { getCreatedStore } from "../createStore";
import { LandType } from "../property/types";
import { DisplayLanguage, LanguageDictionary } from "./DisplayLanguage";

const languages: { [key: string]: LanguageDictionary } = {
  [LandType.FREEHOLD]: {
    [DisplayLanguage.en]: "Freehold",
    [DisplayLanguage.zg]: "ဘိုးဘြားပိုင္",
    [DisplayLanguage.mm]: "ဘိုးဘွားပိုင်",
    [DisplayLanguage.cn]: "Freehold",
  },
  [LandType.GRANT]: {
    [DisplayLanguage.en]: "Grant",
    [DisplayLanguage.zg]: "ဂရမ္",
    [DisplayLanguage.mm]: "ဂရမ်",
    [DisplayLanguage.cn]: "Grant",
  },
  [LandType.AGRICULTURAL]: {
    [DisplayLanguage.en]: "Agricultural",
    [DisplayLanguage.zg]: "လယ္ေျမ",
    [DisplayLanguage.mm]: "လယ်မြေ",
    [DisplayLanguage.cn]: "Agricultural",
  },
  [LandType.GARDEN]: {
    [DisplayLanguage.en]: "Garden",
    [DisplayLanguage.zg]: "ယာေျမ",
    [DisplayLanguage.mm]: "ယာမြေ",
    [DisplayLanguage.cn]: "Garden",
  },
  [LandType.ZONE]: {
    [DisplayLanguage.en]: "Zone",
    [DisplayLanguage.zg]: "စက္မွဳဇုုန္",
    [DisplayLanguage.mm]: "စက်မှုဇုန်",
    [DisplayLanguage.cn]: "工业区",
  },
};

function landTypeOptions() {
  const store = getCreatedStore();
  var language =
    store.getState().site.userSession.language ?? DisplayLanguage.en;
  const options: {
    [key: string]: LandTypeOptionType;
  } = {
    [LandType.FREEHOLD]: {
      value: LandType.FREEHOLD,
      text: languages[LandType.FREEHOLD][language],
    },
    [LandType.GRANT]: {
      value: LandType.GRANT,
      text: languages[LandType.GRANT][language],
    },
    [LandType.AGRICULTURAL]: {
      value: LandType.AGRICULTURAL,
      text: languages[LandType.AGRICULTURAL][language],
    },
    [LandType.GARDEN]: {
      value: LandType.GARDEN,
      text: languages[LandType.GARDEN][language],
    },
    [LandType.ZONE]: {
      value: LandType.ZONE,
      text: languages[LandType.ZONE][language],
    },
  };
  return options;
}

function LandTypeOptionList() {
  const options = landTypeOptions();
  return Object.keys(options).map((e) => options[e]);
}

type LandTypeOptionType = {
  value: LandType;
  text: string;
};

export {
  languages as LandTypeLanguage,
  landTypeOptions  ,
  LandTypeOptionList ,
  LandTypeOptionType ,
};
