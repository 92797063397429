import * as React from "react";
import * as ReactDOM from "react-dom";
import { ApolloProvider } from "react-apollo";
import CssBaseline from "@material-ui/core/CssBaseline";
import MainSnackbar from "./home/MainSnackbar";
import createApolloClient from "./createApolloClient";
import { createClientStore } from "./createStore";
import { BrowserRouter } from "react-router-dom";
import Home from "./home";
import { Provider } from "react-redux";
import LoadingBar from "react-redux-loading-bar";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./theme";
import ScreenInfoContext, { getDeviceTypeFromDimension } from "./common/ScreenInfoContext";
import { Helmet } from "react-helmet";
const deviceType = getDeviceTypeFromDimension(window.screen.width,window.screen.height);
const init = async () => {
  const persistantStore = await createClientStore();
  const apolloClient = createApolloClient(persistantStore.store);
  function Root() {
    React.useEffect(() => {
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }, []);    
    return (
      <ThemeProvider theme={theme}>
        <Provider store={persistantStore.store}>
          <BrowserRouter>
            <React.Fragment>
              <CssBaseline />
              <LoadingBar style={{ zIndex: 200000 }} />
              <MainSnackbar />
              <ApolloProvider client={apolloClient}>
              <ScreenInfoContext.Provider
                  value={{
                    width: window.screen.width,
                    height: window.screen.height,
                    type: deviceType
                  }}
                >                  
                <Home />
                </ScreenInfoContext.Provider>
              </ApolloProvider>
            </React.Fragment>
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    );
  }
  ReactDOM.hydrate(<Root />, document.querySelector("#site"));
};

init();
