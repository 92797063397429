import {
  createStore,
  compose,
  Middleware,
  MiddlewareAPI,
  Dispatch,
  applyMiddleware,
} from "redux";
import { rootReducer, RootAction, RootState } from "./reducer";
import { persistStore, persistReducer, Persistor } from "redux-persist";
import { Store } from "redux";
import { createWhitelistFilter } from "redux-persist-transform-filter";
import update from "immutability-helper";
import { UserSession } from "./reducer/site";
import { isSSR } from "./utility";
import { composeWithDevTools } from 'redux-devtools-extension';
const asyncDispatchMiddleWare: Middleware = (middleWareapi: MiddlewareAPI) => (
  next: Dispatch<RootAction>
) => (action: RootAction) => {
  let syncActivityFinished = false;
  let actionQueue: RootAction[] = [];
  function flushQueue() {
    actionQueue.forEach((a) => middleWareapi.dispatch(a));
    actionQueue = [];
  }
  function asyncDispatch(asyncAction: RootAction) {
    actionQueue = actionQueue.concat([asyncAction]);

    if (syncActivityFinished) {
      flushQueue();
    }
  }
  const actionWithAsyncDispatch = Object.assign({}, action, {
    asyncDispatch,
  });

  next(actionWithAsyncDispatch);
  syncActivityFinished = true;
  flushQueue();
};

let clientStore: Store<RootState, RootAction> = undefined;
let serverStore: Store<RootState, RootAction> = undefined;
export const createClientStore = () => {
  const storage = require("redux-persist/lib/storage");
  const autoMergeLevel2 = require("redux-persist/lib/stateReconciler/autoMergeLevel2");
  const persistConfig = {
    key: "root",
    storage: storage.default,
    whitelist: ["site"],
    blacklist: ["loadingBar"],
    transforms: [createWhitelistFilter("site", ["userSession", "periodId"])],
    stateReconciler: autoMergeLevel2.default,
  };

  const persistedReducer = persistReducer<RootState, RootAction>(
    persistConfig,
    rootReducer
  );
  const promise = new Promise<{
    store: Store<RootState, RootAction>;
    persistor: Persistor;
  }>((resolve, reject) => {
    try {
      clientStore = createStore<RootState, RootAction, {}, {}>(
        persistedReducer,
        composeWithDevTools(
          applyMiddleware(asyncDispatchMiddleWare)
        )
      );
      let persistor = persistStore(clientStore, {}, () =>
        resolve({ store: clientStore, persistor })
      );
    } catch (e) {
      reject(e);
    }
  });
  return promise;
};

const createServerStore = (userSession: UserSession) => {
  let initialState = rootReducer(
    {
      site: undefined,
      loadingBar: undefined,
    },
    { type: "" }
  );
  if (userSession)
    initialState = update(initialState, {
      site: {
        userSession: {
          $set: userSession,
        },
      },
    });
  const persistor: Persistor = undefined;

  serverStore = createStore<RootState, RootAction, {}, {}>(
    rootReducer,
    initialState,
    composeWithDevTools(
      applyMiddleware(asyncDispatchMiddleWare)
    )
  );
  return { store:serverStore, persistor };
};

const getCreatedStore = () => {
  return isSSR? serverStore: clientStore;
};
export { getCreatedStore, createServerStore };
