import Card from "@material-ui/core/Card/Card";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import Grid from "@material-ui/core/Grid/Grid";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography/Typography";
import Skeleton from "@material-ui/lab/Skeleton";
import * as React from "react";
import ScreenInfoContext from "../common/ScreenInfoContext";
const useStyles = makeStyles((theme) => ({
  fullSpacing: {
    padding: theme.spacing(1),
  },
  halfSpacing: {
    padding: theme.spacing(0.5),
  },
  card: {
    width: "100%",
  },

  cartContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

export type EmptyPropertyItemCardProps = {
  style: React.CSSProperties;
};
function EmptyPropertyItemCard({ style }: EmptyPropertyItemCardProps) {
  const classes = useStyles();
  const screenInfo = React.useContext(ScreenInfoContext);
  return (
    <div
      className={screenInfo.type == "phone" ? classes.halfSpacing : classes.fullSpacing}
      style={style}
    >
      <Card className={classes.card}>
        <Skeleton variant="rect" animation="wave" height={200} />
        <ListItem>
          <ListItemText
            secondary={<Skeleton animation="wave" width={150} />}
            primary={<Skeleton animation="wave" width={130} />}
          />
        </ListItem>
        <Grid container className={classes.cartContent}>
          <Grid item xs={12} sm={7}>
            <Typography variant="body2">
              <Skeleton animation="wave" width={64} />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography align="right" variant="subtitle1">
              <Skeleton animation="wave" width={48} />
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default EmptyPropertyItemCard;
