import * as React from "react";
import { Route } from "react-router-dom";
export type HttpStatusProps = {
  code: number;
  children: React.ReactNode;
};
export default function HttpStatus({ code, children }: HttpStatusProps) {
  return (
    <Route
      render={({ staticContext }: any) => {
        if (staticContext) staticContext.status = code;
        return children;
      }}
    />
  );
}

export function InternalServerError({ error }: { error?: string }) {
  return (
    <HttpStatus code={500}>
      <div>
        <h1>Internal server occur</h1>
        {error ? <p>{error}</p> : null}
      </div>
    </HttpStatus>
  );
}

export function NotFound() {
  return (
    <HttpStatus code={404}>
      <div>
        <h1>Sorry, can’t find that.</h1>
      </div>
    </HttpStatus>
  );
}
