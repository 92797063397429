import { Theme } from "@material-ui/core/styles/createMuiTheme";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { isSSR } from "./utility";

const useGlobalStyle = makeStyles((theme: Theme) => ({
  "@global": {
    ".bg": {
      backgroundColor: theme.palette.type === "dark" ? "#303030" : "#FAFAFA",
    },
    ".bg-up-1": {
      backgroundColor: theme.palette.type === "dark" ? "#1E1E1E1E" : "#FFF",
    },
    ".bg-down-1": {
      backgroundColor:
        theme.palette.type === "dark"
          ? "rgba(255,255,255,.05)"
          : "rgba(0,0,0,.1)",
    },
    ".onhover .hidden": {
      display: "none",
    },
    ".onhover:hover .hidden": {
      display: "block",
    },
    ".carousel .slide img": {
      maxHeight: 400,
      objectFit: "contain",
      backgroundColor: "#fff",
    },

    ".carousel .thumb img": {
      maxHeight: 40,
      objectFit: "contain",
    },

    ".carousel-root .carousel .thumbs-wrapper": {
      margin: 0,
    },

    ".carousel-root.fullscreen": {
      position: "absolute",
      top: "50%",
      left: 0,
      transform: "translateY(-50%)",
    },
    ".carousel-root.fullscreen .carousel .slide img": {
      maxHeight: isSSR ? 600 : window.innerHeight,
    },
    ".carousel-root .carousel .control-dots .dot": {
      backgroundColor: "#fff",
      borderWidth: 1,
      borderColor: "#fff",
      borderStyle: "solid",
      marginLeft: 4,
      marginRight: 4,
      opacity: 1,
    },
    ".carousel-root .carousel .control-dots .dot.selected": {
      backgroundColor: theme.palette.secondary.dark,
      borderColor: theme.palette.secondary.dark,
    },

    "#portal .backdrop": {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,.7)",
      zIndex:1200
    },
  },
}));
export default useGlobalStyle;
