import * as React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../reducer";
import { DisplayLanguage } from "./DisplayLanguage";
import { Rabbit } from "./Rabbit";
import Skeleton from "@material-ui/lab/Skeleton";
import CancellablePromise from "./CancellablePromise";
export type MMTextProps = {
  children: string;
  skeletonWidth?: number;
  useSkeleton?: boolean;
};
function MMText({
  children,
  skeletonWidth = 64,
  useSkeleton = true,
}: MMTextProps) {
  const language = useSelector(
    (store: RootState) => store.site.userSession.language
  );
  const [value, setValue] = React.useState(children);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (language == DisplayLanguage.zg && children) {
      setLoading(true);
      const { cancel, promise } = CancellablePromise(
        Rabbit.uni2zgAsync(children)
      );
      promise
        .then((zawgyi) => {
          setLoading(false);
          setValue(zawgyi);
        })
        .catch((e) => {
          if (e != 1) return Promise.reject(e);
        });
      return () => {
        cancel(1);
      };
    }
  }, [children, language]);

  return (
    <>
      {loading ? (
        useSkeleton ? (
          <Skeleton variant="text" width={skeletonWidth} />
        ) : (
          children
        )
      ) : (
        value
      )}
    </>
  );
}
export default MMText;
